@if (recruiterForm) {
  <form class="recruiter-form" [formGroup]="recruiterForm" (ngSubmit)="submit()">
    <header class="header">
      <h2 class="header__page-title">Recruteurs présents</h2>
      <ul class="form-controls ul-clean">
        <div class="form-controls__btn">
          <li class="form-controls__btn__cancel">
            <button class="btn-clean btn-emphasis-white" (click)="reset()" type="button">Annuler</button>
          </li>
          <li class="form-controls__btn__submit">
            <button class="btn-clean btn-emphasis-primary" type="submit">
              <mat-icon
                class="btn-emphasis-primary__icon--left"
                matPrefix
                svgIcon="save"
                aria-hidden="true"
                focusable="false"
              />Sauvegarder
            </button>
          </li>
        </div>
      </ul>
    </header>
    <div class="recruiter-form__content">
      <mat-form-field class="input-simple" appearance="outline">
        <mat-label>Prénom</mat-label>
        <input type="text" formControlName="firstname" matInput />
        <mat-error>Le prénom est requis</mat-error>
      </mat-form-field>
      <mat-form-field class="input-simple" appearance="outline">
        <mat-label>Nom</mat-label>
        <input type="text" formControlName="lastname" matInput />
        <mat-error>Le nom est requis</mat-error>
      </mat-form-field>
      <mat-form-field class="input-simple" appearance="outline">
        <mat-label>Téléphone</mat-label>
        <input type="text" formControlName="phone" matInput />
        <mat-error>Format invalide</mat-error>
      </mat-form-field>
      <mat-form-field class="input-simple" appearance="outline">
        <mat-label>E-mail</mat-label>
        <input type="text" formControlName="mail" matInput />
        <mat-error>Format invalide</mat-error>
      </mat-form-field>
      <mat-form-field class="input-simple" appearance="outline">
        <mat-label>Fonction</mat-label>
        <input type="text" formControlName="position" matInput />
      </mat-form-field>
    </div>
  </form>
}
