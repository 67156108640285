import { OpsJobStatusEnum } from '@wizbii/utils/models';

export const JOB_OFFER_STATUS_DISPLAY = {
  [OpsJobStatusEnum.STATUS_DRAFT]: {
    label: 'Brouillon',
    icon: 'file-no-detail',
    scssClass: 'grey',
    edit: true,
  },
  [OpsJobStatusEnum.STATUS_PENDING]: {
    label: 'En attente de validation',
    icon: 'clock',
    scssClass: 'warning',
  },
  [OpsJobStatusEnum.STATUS_VALIDATED]: { label: 'Validé', icon: 'yes', scssClass: 'success' },
  [OpsJobStatusEnum.STATUS_REJECTED]: { label: 'Refusé', icon: 'no', scssClass: 'error' },
};
