/* eslint-disable sonarjs/no-duplicate-string */
import { JobDetailComponent } from './jobs/job-detail/job-detail.component';
import { JobsRoutingEnum } from './jobs/jobs-routing.enum';
import { JobsComponent } from './jobs/jobs/jobs.component';
import { RecruiterDetailComponent } from './recruiters/recruiter-detail/recruiter-detail.component';
import { RecruitersRoutingEnum } from './recruiters/recruiters-routing.enum';
import { RecruitersComponent } from './recruiters/recruiters/recruiters.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from '@commons/components/not-found/not-found.component';
import { AuthGuard } from '@commons/guards/auth/auth.guard';
import { FormGuard } from '@commons/guards/form/form.guard';
import { IsSourcingOpenGuard } from '@commons/guards/sourcing-open/is-sourcing-open.guard';
import { environment } from '@environment';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { CvBookInterceptor } from '@webservices/cv-book-api/cv-book.interceptor';
import { IMAGINARY_PIPE_CONFIG } from '@wizbii-utils/angular/pipes';
import {
  CityWebservice,
  ContractWebservice,
  DegreeWebservice,
  FILE_API_CONFIG,
  FileWebservice,
  JOB_API_CONFIG,
  PLACE_API_CONFIG,
  SCHOOL_API_CONFIG,
} from '@wizbii-utils/angular/webservices';
import { QuicklinkModule } from 'ngx-quicklink';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadComponent: () => import('./home/home.component').then((cmp) => cmp.HomeComponent),
        data: { hideNavbar: true, fullScreen: true },
      },
      {
        path: FeaturesRoutingEnum.Company,
        loadComponent: () => import('./company/company.component').then((cmp) => cmp.CompanyComponent),
        data: { hideNavbar: false, fullScreen: false },
      },
      {
        path: FeaturesRoutingEnum.Recruiters,
        data: { hideNavbar: false, fullScreen: false },
        loadChildren: () => [
          {
            path: RecruitersRoutingEnum.List,
            component: RecruitersComponent,
          },
          {
            path: RecruitersRoutingEnum.Create,
            component: RecruiterDetailComponent,
            canDeactivate: [FormGuard],
          },
          {
            path: RecruitersRoutingEnum.RecruiterDetail,
            component: RecruiterDetailComponent,
            canDeactivate: [FormGuard],
          },
        ],
      },
      {
        path: FeaturesRoutingEnum.Jobs,
        data: { hideNavbar: false, fullScreen: false },
        loadChildren: () => [
          {
            path: JobsRoutingEnum.List,
            component: JobsComponent,
          },
          {
            path: JobsRoutingEnum.Create,
            component: JobDetailComponent,
            canDeactivate: [FormGuard],
            canActivate: [IsSourcingOpenGuard],
          },
          {
            path: JobsRoutingEnum.JobDetail,
            component: JobDetailComponent,
            canDeactivate: [FormGuard],
          },
        ],
      },
      {
        path: FeaturesRoutingEnum.Candidates,
        data: { hideNavbar: false, fullScreen: false },
        loadComponent: () =>
          import('./candidates/candidates/candidates.component').then((cmp) => cmp.CandidatesComponent),
      },
      {
        path: FeaturesRoutingEnum.Infos,
        data: { hideNavbar: false, fullScreen: false },
        loadComponent: () => import('./infos/infos.component').then((cmp) => cmp.InfosComponent),
      },
      {
        path: '**',
        component: NotFoundComponent,
        data: { hideNavbar: true, fullScreen: true },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes), QuicklinkModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CvBookInterceptor,
      multi: true,
    },
    FileWebservice,
    {
      provide: FILE_API_CONFIG,
      useValue: {
        baseUrl: environment.api.file,
        googleStorageUrl: environment.api.googleStorage,
        wizbiiFilesBucket: environment.wizbiiFiles,
      },
    },
    ContractWebservice,
    {
      provide: SCHOOL_API_CONFIG,
      useValue: {
        baseUrl: environment.api.school,
      },
    },
    {
      provide: JOB_API_CONFIG,
      useValue: {
        baseUrl: environment.api.job,
      },
    },
    {
      provide: IMAGINARY_PIPE_CONFIG,
      useValue: {
        imaginaryUrl: environment.api.imaginary,
      },
    },
    DegreeWebservice,
    ContractWebservice,
    DegreeWebservice,
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    CityWebservice,
    {
      provide: PLACE_API_CONFIG,
      useValue: {
        baseUrl: environment.api.place,
      },
    },
  ],
})
export class FeaturesModule {}
