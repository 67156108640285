import { isPlatformBrowser } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  HostListener,
  OnInit,
  PLATFORM_ID,
  computed,
  inject,
} from '@angular/core';
import { BreakpointsService } from '@core/services/breakpoints/breakpoints.service';
import { selectIfDefinedSig } from '@core/utils/selects.operators';
import { environment } from '@environment';
import { RouterState } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { BreadcrumbsState } from '@stores/breadcrumb/breadcrumb.state';
import { WebVitalsOptions, WebVitalsParams, sendWebVitals } from '@wizbii/utils';
import { fromEvent } from 'rxjs';

@Component({
  selector: 'app-core',
  templateUrl: './core.component.html',
  styleUrls: ['./core.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoreComponent implements OnInit {
  /**
   * Use class `hover-on` in CSS as follows:
   * `:host-context(.hover-on) .link:hover { ... }`
   */
  @HostBinding('class.hover-on') hoverEnabled = true;

  @HostBinding('class.accessibility-on') accessibilityEnabled = true;

  @HostBinding('class.hover-off')
  get hoverDisabled(): boolean {
    return !this.hoverEnabled;
  }

  @HostBinding('class.accessibility-off')
  get accessibilityDisabled(): boolean {
    return !this.accessibilityEnabled;
  }

  readonly #store = inject(Store);
  readonly breakpoints = inject(BreakpointsService);
  readonly #platformId = inject(PLATFORM_ID);

  readonly routerState = selectIfDefinedSig(this.#store, RouterState.state);

  readonly hideNavbar = computed(() => {
    const routerState = this.routerState();

    if (!routerState) return false;

    return (routerState as any)?.data?.hideNavbar;
  });

  readonly fullScreen = computed(() => {
    const routerState = this.routerState();

    if (!routerState) return false;

    return (routerState as any)?.data?.fullScreen;
  });

  readonly breadcrumbs = selectIfDefinedSig(this.#store, BreadcrumbsState.breadcrumbs);

  ngOnInit(): void {
    if (document.documentElement.lang === '') {
      document.documentElement.lang = environment.i18n.lang;
    }

    /**
     * Disable hover on `touchstart` to cover browsers that do not support pointer events.
     * https://caniuse.com/#feat=pointer
     */
    fromEvent(window, 'touchstart', { passive: true }).subscribe({
      next: () => {
        this.hoverEnabled = false;
      },
    });

    const routerState = this.#store.selectSnapshot(RouterState)?.state;
    if (routerState?.url) {
      const params: WebVitalsParams = {
        params: routerState.params,
        path: routerState.url,
        applicationId: environment.applicationId,
        envFqdn: environment.apiDomain,
      };
      const options: WebVitalsOptions = {
        dev: environment.platform === 'local',
        debug: environment.platform === 'local',
        browser: isPlatformBrowser(this.#platformId),
      };
      sendWebVitals(params, options);
    }
  }

  /**
   * Enable hover if "mouse" pointer event is detected; disable it otherwise.
   * https://developer.mozilla.org/en-US/docs/Web/Events/pointerenter
   */
  @HostListener('pointerenter', ['$event'])
  onPointerEnter(event: any): void {
    this.hoverEnabled = event.pointerType === 'mouse';
  }

  @HostListener('document:keydown.tab', ['$event'])
  onKeydownTabHandler(_: KeyboardEvent): void {
    this.accessibilityEnabled = true;
  }

  @HostListener('document:mousedown', ['$event'])
  onMouseDownHandler(_: MouseEvent): void {
    this.accessibilityEnabled = false;
  }
}
