import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  OnInit,
  ViewChild,
  computed,
  forwardRef,
  inject,
  input,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipGrid, MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { OpsJobOfferLanguage, opsJobOfferLanguages } from '@wizbii/models/lib/ops-event-api/ops-job-offer-languages';
import { derivedAsync } from 'ngxtension/derived-async';
import { map, startWith } from 'rxjs';

@Component({
  standalone: true,
  selector: 'app-languages-picker',
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatChipsModule,
    ReactiveFormsModule,
    MatIcon,
  ],
  templateUrl: './languages-picker.component.html',
  styleUrls: ['./languages-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LanguagesPickerComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => LanguagesPickerComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguagesPickerComponent implements OnInit, ControlValueAccessor, Validator {
  control = new FormControl();
  selectedLanguages: string[] = [];
  allLanguages = opsJobOfferLanguages;

  readonly searchResults = derivedAsync(() => {
    return this.control.valueChanges.pipe(
      startWith(null),
      takeUntilDestroyed(this.#destroyRef),
      map((language: string) => (language ? this._filter(language) : this.allLanguages.slice()))
    );
  });

  readonly resultsFound = computed(() => {
    const searchResults = this.searchResults();
    return searchResults && searchResults.length > 0;
  });

  // to reset the input field after a language is selected
  @ViewChild('languageInputField') languageInputField!: ElementRef<HTMLInputElement>;

  // to set error state on the mat-chip-list element
  @ViewChild('languageChips') languageChips!: MatChipGrid;

  readonly disabled = input<boolean>(false);

  readonly #destroyRef = inject(DestroyRef);
  readonly #cdr = inject(ChangeDetectorRef);

  ngOnInit(): void {
    this.control.statusChanges.pipe(takeUntilDestroyed(this.#destroyRef)).subscribe({
      next: (status) => {
        this.languageChips.errorState = status === 'INVALID';
      },
    });
  }

  onChange: (value: any) => void = () => {};
  registerOnTouched(): void {}

  writeValue(val: string[]): void {
    this.selectedLanguages = val;
    this.#cdr.markForCheck();
  }

  registerOnChange(fn: (languages: string[]) => void): void {
    this.onChange = (languages) => {
      fn(languages);
    };
  }

  validate(): ValidationErrors | null {
    return null;
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.selectedLanguages.push(event.option.value.code);
    this.languageInputField.nativeElement.value = '';
    this.onChange(this.selectedLanguages);
  }

  remove(language: string): void {
    const index = this.selectedLanguages.indexOf(language);
    if (index >= 0) this.selectedLanguages.splice(index, 1);
    this.onChange(this.selectedLanguages);
    this.control.updateValueAndValidity();
  }

  getLanguageByCode(code: string): OpsJobOfferLanguage | undefined {
    return this.allLanguages.find((language) => language.code === code);
  }

  private _filter(languageInput: string): OpsJobOfferLanguage[] {
    return this.allLanguages.filter(
      (language) =>
        !this.selectedLanguages.includes(language.code) && language.displayName.toLowerCase().includes(languageInput)
    );
  }
}
