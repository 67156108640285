<!-- eslint-disable @angular-eslint/template/no-call-expression -->
<main class="main" [class.fullscreen]="fullScreen() === true">
  @if (hideNavbar() === false) {
    <app-sidenavbar class="main__sidenavbar" />
  }
  <div class="main__content">
    @if ((breakpoints.isMobile$ | async) === false && breadcrumbs() && hideNavbar() === false) {
      <app-breadcrumb class="main__content__breadcrumb" [breadcrumbs]="breadcrumbs()!" />
    }
    <router-outlet />
  </div>
</main>
