import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { CompanyLogoComponent } from '@commons/components/form-components/company-logo/company-logo.component';
import { FEATURE_MENU, FeatureMenu } from '@commons/menu/feature-menu';
import { BreakpointsService } from '@core/services/breakpoints/breakpoints.service';
import { selectIfDefinedSig } from '@core/utils/selects.operators';
import { Store } from '@ngxs/store';
import { CompanyParticipationState } from '@stores/company-participation/company-participation.state';
import { Observable } from 'rxjs';

@Component({
  standalone: true,
  selector: 'app-sidenavbar',
  imports: [RouterModule, CompanyLogoComponent, AsyncPipe, MatIcon],
  templateUrl: './sidenavbar.component.html',
  styleUrls: ['./sidenavbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideNavbarComponent {
  readonly #breakpoints = inject(BreakpointsService);
  readonly #store = inject(Store);

  readonly companyName = selectIfDefinedSig(this.#store, CompanyParticipationState.companyName);
  readonly companyLogo = selectIfDefinedSig(this.#store, CompanyParticipationState.companyLogoFileUrl);
  readonly companyId = selectIfDefinedSig(this.#store, CompanyParticipationState.companyId);

  readonly FEATURE_MENU = FEATURE_MENU;

  get isMobile$(): Observable<boolean> {
    return this.#breakpoints.isMobile$;
  }

  get isTablet$(): Observable<boolean> {
    return this.#breakpoints.isTablet$;
  }

  get featuresMenuKeys(): (keyof FeatureMenu)[] {
    return Object.keys(FEATURE_MENU) as (keyof FeatureMenu)[];
  }
}
