import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@environment';
import { Candidate } from '@models/candidate';
import { CompanyParticipation } from '@models/company-participation';
import { CVBookGeneration } from '@models/cvbook-generation/cvbook-generation-status';
import { Observable, map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CvBookWebservice {
  readonly #httpClient = inject(HttpClient);

  private readonly baseUrl = `${environment.api.cvBook}/api/cvbook`;

  getCompanyParticipation(): Observable<CompanyParticipation> {
    return this.#httpClient.get<CompanyParticipation>(this.baseUrl);
  }

  getCandidatesList(): Observable<Candidate[]> {
    return this.#httpClient.get<Candidate[]>(`${this.baseUrl}/candidates`);
  }

  getCandidate(candidateId: string): Observable<Candidate> {
    return this.#httpClient.get<Candidate>(`${this.baseUrl}/candidates/${candidateId}`);
  }

  getFile(fileId: string): Observable<string> {
    return this.#httpClient
      .get<{ fileUrl: string }>(`${this.baseUrl}/candidates/static/${fileId}`)
      .pipe(map((response) => response.fileUrl));
  }

  triggerCvBookGeneration(): Observable<CVBookGeneration> {
    return this.#httpClient.post<CVBookGeneration>(`${this.baseUrl}/download`, {});
  }

  downloadCvBook(): Observable<CVBookGeneration> {
    return this.#httpClient.get<CVBookGeneration>(`${this.baseUrl}/download/status`);
  }
}
