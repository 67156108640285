import { CandidateEvaluation } from '@models/candidate-evaluation';
import { CandidateParticipationStatusEnum } from '@models/candidate-participation-status.enum';
import { CandidateStatusEnum } from '@models/candidate-status.enum';

export interface Candidate {
  id: string;
  firstname: string;
  lastname: string;
  mail: string;
  phone: string;
  resumeFileId: string;
  annotatedResumeFileId: string | null;
  participationStatus: CandidateParticipationStatusEnum;
  jobsIds: string[];
  candidatedJobsIds: string[];
  globalNotation: CandidateStatusEnum;
  candidateEvaluations: CandidateEvaluation[] | null;
}

export function toCandidate(data: any = {}): Candidate {
  return {
    ...data,
    id: data.candidateId,
    firstname: data.firstName,
    lastname: data.lastName,
    globalNotation: data.globalNotation,
  };
}
