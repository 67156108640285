import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, shareReplay } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class BreakpointsService {
  readonly isMobile$: Observable<boolean>;
  readonly isWeb$: Observable<boolean>;
  readonly isTablet$: Observable<boolean>;
  readonly isWebNotIpadPro$: Observable<boolean>;
  readonly isWebOrTabletLandscape$: Observable<boolean>;
  readonly isTabletLandscape$: Observable<boolean>;
  readonly isTabletPortrait$: Observable<boolean>;
  readonly isMobileAndTabletPortrait$: Observable<boolean>;
  readonly isMobileAndTabletPortraitAndIpadPro$: Observable<boolean>;
  readonly isIpadPro$: Observable<boolean>;

  readonly #breakpointObserver = inject(BreakpointObserver);

  constructor() {
    this.isMobile$ = this.#breakpointObserver.observe([Breakpoints.Handset]).pipe(
      map((result) => result.matches),
      distinctUntilChanged(),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.isWeb$ = this.#breakpointObserver.observe([Breakpoints.Web]).pipe(
      map((result) => result.matches),
      distinctUntilChanged(),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.isWebNotIpadPro$ = this.#breakpointObserver
      .observe([
        '(min-width: 1025px) and (orientation: portrait), ' + '(min-width: 1280px) and (orientation: landscape)',
      ])
      .pipe(
        map((result) => result.matches),
        distinctUntilChanged(),
        shareReplay({ bufferSize: 1, refCount: true })
      );

    this.isWebOrTabletLandscape$ = this.#breakpointObserver
      .observe([Breakpoints.Web, Breakpoints.TabletLandscape])
      .pipe(
        map((result) => result.matches),
        distinctUntilChanged(),
        shareReplay({ bufferSize: 1, refCount: true })
      );

    this.isTabletLandscape$ = this.#breakpointObserver.observe([Breakpoints.TabletLandscape]).pipe(
      map((result) => result.matches),
      distinctUntilChanged(),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.isTabletPortrait$ = this.#breakpointObserver.observe([Breakpoints.TabletPortrait]).pipe(
      map((result) => result.matches),
      distinctUntilChanged(),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.isTablet$ = this.#breakpointObserver.observe([Breakpoints.Tablet]).pipe(
      map((result) => result.matches),
      distinctUntilChanged(),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.isMobileAndTabletPortrait$ = this.#breakpointObserver
      .observe([Breakpoints.Handset, Breakpoints.TabletPortrait])
      .pipe(
        map((result) => result.matches),
        distinctUntilChanged(),
        shareReplay({ bufferSize: 1, refCount: true })
      );

    this.isMobileAndTabletPortraitAndIpadPro$ = this.#breakpointObserver
      .observe([Breakpoints.Handset, '(min-width: 600px) and (max-width: 1024px) and (orientation: portrait)'])
      .pipe(
        map((result) => result.matches),
        distinctUntilChanged(),
        shareReplay({ bufferSize: 1, refCount: true })
      );

    this.isIpadPro$ = this.#breakpointObserver.observe('(max-width: 1024px)').pipe(
      map((result) => result.matches),
      distinctUntilChanged(),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }
}
