<!-- eslint-disable @angular-eslint/template/no-call-expression -->
<article>
  <header class="header">
    <h2 class="header__page-title">
      {{ pageTitle }}
      <!-- eslint-disable @angular-eslint/template/conditional-complexity-->
      <div class="header__page-title__chip" [matTooltip]="matTooltipText" matTooltipPosition="above">
        <span [class]="numJobOffers() !== maxNumJobOffers ? 'chip-light--primary' : 'chip-light--warning'"
          >{{ numJobOffers() }} / {{ maxNumJobOffers }} </span
        ><mat-icon
          [class]="
            'header__page-title__icon header__page-title__icon--' +
            (numJobOffers() !== maxNumJobOffers ? 'primary' : 'warning')
          "
          matPrefix
          svgIcon="info"
          aria-hidden="true"
          focusable="false"
        />
      </div>
    </h2>

    <ul class="form-controls ul-clean">
      <li class="form-controls__btn">
        @if (isJobEditionAllowed()) {
          <button
            class="btn-clean btn-emphasis-primary btn-create-action"
            (click)="clickOnNew(numJobOffers() >= maxNumJobOffers)"
            type="button"
          >
            <mat-icon
              class="btn-emphasis-primary__icon--left btn-create-action__icon"
              matPrefix
              svgIcon="add"
              aria-hidden="true"
              focusable="false"
            />
            <span class="btn-create-action__legend">Nouveau</span>
          </button>
        }
      </li>
    </ul>
  </header>

  @if (jobOffers(); as jobOffers) {
    @if (jobOffers.length > 0) {
      <ul class="jobs ul-clean">
        @for (jobOffer of jobOffers; track jobOffer) {
          <li class="jobs__item">
            <app-job-offer-card [jobOffer]="jobOffer" />
          </li>
        }
      </ul>
    }
  }
</article>
