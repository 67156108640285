import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { SetToken } from '@stores/auth/auth.actions';

export type AuthStateModel = {
  token: string;
};

export const AuthStateToken = new StateToken<AuthStateModel>('token');

@State({
  name: AuthStateToken,
  defaults: null,
})
@Injectable()
export class AuthState {
  @Selector([AuthStateToken])
  static token(state: AuthStateModel): string {
    return state?.token;
  }

  @Selector([AuthStateToken])
  static isAuth(state: AuthStateModel): boolean {
    return !!state?.token;
  }

  @Action(SetToken)
  setToken(ctx: StateContext<AuthStateModel>, { token }: SetToken): void {
    ctx.patchState({ token });
  }
}
