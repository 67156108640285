import { Injectable } from '@angular/core';
import { sortByStatus } from '@commons/utils/sort';
import { Candidate, toCandidate } from '@models/candidate';
import { Action, NgxsOnInit, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { SetCandidates } from '@stores/candidates/candidates.actions';
import { CvBookWebservice } from '@webservices/cv-book-api/cv-book.webservice';

export type CandidatesStateModel = Candidate[] | undefined;

export const CandidatesStateToken = new StateToken<CandidatesStateModel>('candidates');

@State({
  name: CandidatesStateToken,
  defaults: null,
})
@Injectable()
export class CandidatesState implements NgxsOnInit {
  @Selector([CandidatesStateToken])
  static candidates(state: CandidatesStateModel): Candidate[] | undefined {
    return state;
  }

  @Selector([CandidatesState.candidates])
  static candidatesSorted(candidates: Candidate[] | undefined): Candidate[] {
    return [...(candidates || [])]?.sort(sortByStatus);
  }

  constructor(private readonly cvBookWebservice: CvBookWebservice) {}

  ngxsOnInit(ctx: StateContext<CandidatesStateModel>): void {
    this.cvBookWebservice.getCandidatesList().subscribe({
      next: (candidateList: Candidate[]) => {
        ctx.dispatch(new SetCandidates(candidateList.map((candidate) => toCandidate(candidate))));
      },
    });
  }

  @Action(SetCandidates)
  setCandidates(ctx: StateContext<CandidatesStateModel>, { candidates }: SetCandidates): void {
    ctx.setState(candidates);
  }
}
