import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, inject, signal } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { ToasterConfig, ToasterTypeEnum } from '@models/toaster-config';

export const TOASTER_DEFAULT_CONFIG = {
  duration: 3000,
  message: {
    success: 'Vos modifications ont été sauvegardées',
    error: "Vos modifications n'ont pas pu être sauvegardées",
  },
};

@Component({
  standalone: true,
  selector: 'app-toaster',
  imports: [CommonModule, MatIcon, MatProgressBarModule],
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToasterComponent implements OnInit, OnDestroy {
  interval: any;

  message!: string;
  duration!: number;

  readonly progressValue = signal<number>(0);

  readonly data: ToasterConfig = inject(MAT_SNACK_BAR_DATA);

  get icon(): string {
    return this.data?.type === ToasterTypeEnum.SUCCESS
      ? 'yes'
      : this.data?.type === ToasterTypeEnum.ERROR
        ? 'no'
        : 'exclamation-mark';
  }

  ngOnInit(): void {
    this.message = this.data?.message
      ? this.data.message
      : this.data?.type === ToasterTypeEnum.SUCCESS
        ? TOASTER_DEFAULT_CONFIG.message.success
        : TOASTER_DEFAULT_CONFIG.message.error;

    this.duration = this.data?.duration || TOASTER_DEFAULT_CONFIG.duration;

    this.interval = setInterval(() => {
      this.progressValue.update((progressValue) => progressValue + 20);
    }, this.duration / 5);
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }
}
