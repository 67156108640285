import { APP_BASE_HREF, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import { ErrorHandler, LOCALE_ID, NgModule, NgZone, inject } from '@angular/core';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { BreadcrumbComponent } from '@commons/components/breadcrumb/breadcrumb.component';
import { SideNavbarComponent } from '@commons/components/sidenavbar/sidenavbar.component';
import { TOASTER_DEFAULT_CONFIG } from '@commons/components/toaster/toaster.component';
import { AuthGuard } from '@commons/guards/auth/auth.guard';
import { FormGuard } from '@commons/guards/form/form.guard';
import { bugsnagConfig } from '@config/bugsnag.config';
import { ngxsConfig } from '@config/ngxs.config';
import { routerConfig } from '@config/router.config';
import { CoreComponent } from '@core/components/core/core.component';
import { WizbiiRouterStateSerializer } from '@core/serializer/router.serializer';
import { environment } from '@environment';
import { FeaturesModule } from '@features/features.module';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsRouterPluginModule, RouterStateSerializer } from '@ngxs/router-plugin';
import { NgxsModule } from '@ngxs/store';
import { AuthState } from '@stores/auth/auth.state';
import { BreadcrumbsState } from '@stores/breadcrumb/breadcrumb.state';
import { CandidatesState } from '@stores/candidates/candidates.state';
import { CompanyParticipationState } from '@stores/company-participation/company-participation.state';
import { bugsnagErrorHandlerFactory } from '@wizbii-utils/angular/bugsnag';
import { ImageService } from '@wizbii-utils/angular/core';
import { LocaleEnum } from '@wizbii/models';
import { NgChartsModule } from 'ng2-charts';
import { QuicklinkModule } from 'ngx-quicklink';
import { NG_SCROLLBAR_OPTIONS } from 'ngx-scrollbar';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

registerLocaleData(localeFr);

@NgModule({
  declarations: [CoreComponent],
  imports: [
    BrowserAnimationsModule,
    HttpClientModule,
    FeaturesModule,
    NgChartsModule,
    QuicklinkModule,
    SideNavbarComponent,
    BreadcrumbComponent,
    MatSnackBarModule,
    RouterModule.forRoot([], routerConfig),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsRouterPluginModule.forRoot(),
    NgxsModule.forRoot([AuthState, CompanyParticipationState, CandidatesState, BreadcrumbsState], ngxsConfig),
    NgxSkeletonLoaderModule.forRoot({
      theme: {
        extendsFromRoot: true,
        'border-radius': '20px',
        margin: 0,
      },
    }),
  ],
  providers: [
    AuthGuard,
    FormGuard,
    { provide: LOCALE_ID, useValue: LocaleEnum.fr_FR },
    { provide: APP_BASE_HREF, useValue: '/' },
    {
      provide: ErrorHandler,
      useFactory: bugsnagErrorHandlerFactory(bugsnagConfig),
    },
    {
      provide: ImageService,
      useValue: new ImageService(environment.api.imaginary),
    },
    { provide: RouterStateSerializer, useClass: WizbiiRouterStateSerializer },

    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: { panelClass: 'wiz-dialog', backdropClass: 'wiz-backdrop', hasBackdrop: true },
    },
    {
      provide: NG_SCROLLBAR_OPTIONS,
      useValue: { scrollAuditTime: 20, appearance: 'compact' },
    },
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: TOASTER_DEFAULT_CONFIG.duration } },
  ],
  bootstrap: [CoreComponent],
})
export class CoreModule {
  readonly #matIconRegistry = inject(MatIconRegistry);
  readonly #domSanitizer = inject(DomSanitizer);
  readonly #ngZone = inject(NgZone);

  constructor() {
    this.#ngZone.runOutsideAngular(() => {
      this.#matIconRegistry.addSvgIconSet(this.#domSanitizer.bypassSecurityTrustResourceUrl(`/assets/sprite.svg`));
    });
  }
}
