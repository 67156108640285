<!-- eslint-disable @angular-eslint/template/no-call-expression -->
<div class="toaster">
  <mat-progress-bar [value]="progressValue()" [ngClass]="'mat-progress-bar--' + data.type" />
  <p class="toaster__message">
    <mat-icon
      class="toaster__message__icon"
      [svgIcon]="icon"
      [ngClass]="'toaster__message__icon--' + data.type"
      aria-hidden="true"
      focusable="false"
    />{{ message }}
  </p>
</div>
