import { ChangeDetectionStrategy, Component, effect, input } from '@angular/core';
import { MatIcon } from '@angular/material/icon';

@Component({
  standalone: true,
  selector: 'app-company-logo',
  imports: [MatIcon],
  templateUrl: './company-logo.component.html',
  styleUrls: ['./company-logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyLogoComponent {
  readonly companyLogoUrl = input<string | undefined | null>();
  readonly companyName = input<string | undefined | null>();

  constructor() {
    effect(() => {
      console.log('companyLogoUrl', this.companyLogoUrl());
    });
  }
}
