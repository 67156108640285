import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { Menu } from '@models/menu';

export type FeatureMenu = Menu<Exclude<FeaturesRoutingEnum, FeaturesRoutingEnum.Home | FeaturesRoutingEnum.NotFound>>;

export const FEATURE_MENU: FeatureMenu = {
  [FeaturesRoutingEnum.Company]: {
    label: 'Mon entreprise',
    shortLabel: 'Entreprise',
    path: FeaturesRoutingEnum.Company,
    icon: 'building',
  },
  [FeaturesRoutingEnum.Jobs]: {
    label: 'Fiches de poste',
    shortLabel: 'Postes',
    path: FeaturesRoutingEnum.Jobs,
    icon: 'suitcase',
  },
  [FeaturesRoutingEnum.Recruiters]: {
    label: 'Recruteurs présents',
    shortLabel: 'Recruteurs',
    path: FeaturesRoutingEnum.Recruiters,
    icon: 'people',
  },

  [FeaturesRoutingEnum.Candidates]: {
    label: 'CV Book',
    shortLabel: 'CV Book',
    path: FeaturesRoutingEnum.Candidates,
    icon: 'file',
  },
  [FeaturesRoutingEnum.Infos]: {
    label: 'Infos & Contact',
    shortLabel: 'Infos',
    path: FeaturesRoutingEnum.Infos,
    icon: 'info',
  },
};
