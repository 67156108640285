<!-- eslint-disable @angular-eslint/template/no-call-expression -->
<mat-form-field class="custom-chips" appearance="outline">
  <mat-label>Langues</mat-label>

  <mat-chip-grid [disabled]="disabled()" #languageChips aria-label="Sélection des langues">
    @for (language of selectedLanguages; track language) {
      <mat-chip-row (removed)="remove(language)">
        {{ getLanguageByCode(language)?.displayName }}
        @if (!disabled()) {
          <button class="button-remove" matChipRemove type="button">
            <mat-icon class="button-remove__icon" svgIcon="cross" />
          </button>
        }
      </mat-chip-row>
    }
    <input
      [placeholder]="disabled() || selectedLanguages.length ? '' : 'Ajouter une langue'"
      [formControl]="control"
      [matAutocomplete]="languageAutocomplete"
      [matChipInputFor]="languageChips"
      #languageInputField
    />
  </mat-chip-grid>

  <mat-autocomplete (optionSelected)="selected($event)" #languageAutocomplete="matAutocomplete">
    @for (language of searchResults(); track language) {
      <mat-option class="mat-option" [value]="language">{{ language.displayName }}</mat-option>
    }

    @if (resultsFound() === false) {
      <mat-optgroup class="mat-optgroup--error" label="Aucune suggestion ne correspond à votre saisie.">
        <mat-option class="invisible-option" />
      </mat-optgroup>
    }
  </mat-autocomplete>
</mat-form-field>
