import { ChangeDetectionStrategy, Component, DestroyRef, OnInit, inject, input, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { MatSlideToggleChange, MatSlideToggleModule } from '@angular/material/slide-toggle';
import { RouterModule } from '@angular/router';
import { InfoModalComponent } from '@commons/components/modals/info-modal/info-modal.component';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { RecruiterData } from '@models/recruiter-data';
import { RecruiterWebservice } from '@webservices/cv-book-api/recruiter/recruiter.webservice';
import { EMPTY, Observable, catchError } from 'rxjs';

@Component({
  standalone: true,
  selector: 'app-recruiter-card',
  imports: [RouterModule, MatSlideToggleModule, MatIcon],

  templateUrl: './recruiter-card.component.html',
  styleUrls: ['./recruiter-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecruiterCardComponent implements OnInit {
  readonly recruiter = input<RecruiterData>();
  readonly eventId = input<string>();

  readonly checked = signal<boolean>(false);

  readonly FeaturesRoutingEnum = FeaturesRoutingEnum;

  #recruiterWebservice = inject(RecruiterWebservice);
  modalDialog = inject(MatDialog);
  readonly #destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    const eventId = this.eventId();

    if (eventId) {
      this.checked.set(!!(eventId && this.recruiter()?.eventIds?.includes(eventId)));
    }
  }

  get recruiterStatusLabel(): string {
    return this.checked() ? 'Présent' : 'Absent';
  }

  recruiterStatusChanged(event: MatSlideToggleChange): void {
    const recruiter = this.recruiter();

    if (!recruiter || typeof recruiter.id === 'undefined') return;

    this.#updateRecruiterParticipation(event.checked, recruiter.id)
      .pipe(
        takeUntilDestroyed(this.#destroyRef),
        catchError(() => {
          this.modalDialog.open(InfoModalComponent, {
            data: {
              title: 'Opération impossible',
              subtitle:
                "La modification de ce recruteur n’est plus possible depuis votre Espace, veuillez contacter l'équipe d’organisation pour signaler cette absence",
              navBtn: {
                routerLink: FeaturesRoutingEnum.Recruiters,
                wording: 'Retour',
              },
            },
          });
          this.checked.set(!event.checked);
          return EMPTY;
        })
      )
      .subscribe();
  }

  #updateRecruiterParticipation(present: boolean, recruiterId: string): Observable<void> {
    this.checked.set(present);

    const eventId = this.eventId();

    if (!eventId) return EMPTY;

    return present
      ? this.#recruiterWebservice.addRecruiterToEvent(recruiterId, eventId)
      : this.#recruiterWebservice.removeRecruiterFromEvent(recruiterId, eventId);
  }
}
