import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { InfoModal } from '@models/modals/info-modal';

@Component({
  standalone: true,
  selector: 'app-info-modal',
  imports: [RouterModule, MatButtonModule, MatDialogModule],
  providers: [MatDialog],
  templateUrl: './info-modal.component.html',
  styleUrls: ['./info-modal.component.scss'],
})
export class InfoModalComponent {
  readonly dialogRef = inject(MatDialogRef<InfoModalComponent>);
  readonly data: InfoModal = inject(MAT_DIALOG_DATA);
}
