/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, UrlTree } from '@angular/router';
import { AuthService } from '@webservices/auth/auth.service';
import { Observable } from 'rxjs';

@Injectable()
export class AuthGuard {
  readonly #authService = inject(AuthService);

  isAuth(token?: string | null): boolean {
    if (!token) {
      return false;
    }
    this.#authService.setTokenCookie(token);
    this.#authService.setTokenInStore(token);

    return true;
  }

  canActivate(route: ActivatedRouteSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> {
    const tokenQueryParam = route.queryParamMap.get('token');
    const tokenParam = route.paramMap.get('token');
    return this.isAuth(tokenParam ?? tokenQueryParam ?? this.#authService.getTokenFromCookies());
  }
}
