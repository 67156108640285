import { NgxSliderModule, Options } from '@angular-slider/ngx-slider';
import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  OnDestroy,
  OnInit,
  computed,
  inject,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CKEditorModule, ChangeEvent } from '@ckeditor/ckeditor5-angular';
import InlineEditor from '@ckeditor/ckeditor5-build-inline';
import { ckEditorConfig } from '@commons/ckeditor/config';
import { LanguagesPickerComponent } from '@commons/components/form-components/languages-picker/languages-picker.component';
import { QualitiesPickerComponent } from '@commons/components/form-components/qualities-picker/qualities-picker.component';
import { SoftwareSkillsPickerComponent } from '@commons/components/form-components/software-skills-picker/software-skills-picker.component';
import { ConfirmModalComponent } from '@commons/components/modals/confirm-modal/confirm-modal.component';
import { InfoModalComponent } from '@commons/components/modals/info-modal/info-modal.component';
import { ToasterComponent } from '@commons/components/toaster/toaster.component';
import { FormStatusWhenLeavedEnum } from '@commons/guards/form/form.guard';
import { BreakpointsService } from '@core/services/breakpoints/breakpoints.service';
import { selectIfDefinedSig } from '@core/utils/selects.operators';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { CONTRACT_DURATION_DISPLAY } from '@models/job-contract-duration_display';
import { ToasterTypeEnum } from '@models/toaster-config';
import { Store } from '@ngxs/store';
import { FormSubmitStatusEnum } from '@shared/form-status.enum';
import { AddBreadcrumb, RemoveCustomBreadcrumbs } from '@stores/breadcrumb/breadcrumb.actions';
import { CompanyParticipationState } from '@stores/company-participation/company-participation.state';
import { JobOfferWebservice } from '@webservices/cv-book-api/job-offer/job-offer.webservice';
import { WzbPlacePickerModule } from '@wizbii-components/angular-ui';
import { ContractWebservice, DegreeWebservice } from '@wizbii-utils/angular/webservices';
import {
  City,
  Contract,
  LocaleEnum,
  OpsJob,
  OpsJobDomains,
  OpsJobEffectiveDurationEnum,
  OpsJobExperienceEnum,
  OpsJobStatusEnum,
  toOpsJob,
} from '@wizbii/utils/models';
import { derivedAsync } from 'ngxtension/derived-async';
import { EMPTY, Observable, catchError, combineLatest, filter, map, switchMap, take } from 'rxjs';

export interface JobOfferForm {
  name: FormControl<string | null>;
  effectiveDuration: FormControl<string | null>;
  effectiveHoursPerWeek: FormControl<number | null | undefined>;
  domain: FormControl<string | null>;
  experience: FormControl<string | null>;
  locations: FormControl<City[] | null>;
  missions: FormControl<string | null>;
  qualities: FormControl<string[] | null>;
  languages: FormControl<string[] | null>;
  softwareSkills: FormControl<string[] | null>;
  driversLicenceRequired: FormControl<boolean | null>;
  degreeId: FormControl<string | null>;
  contractIds: FormControl<string[] | null>;
  contractDuration: FormControl<number | null>;
  goodPoints: FormControl<string | null>;
  startDate: FormControl<Date | null>;
  salaryRange: FormControl<[number, number] | null>;
  openPositions: FormControl<number | null>;
  confidential: FormControl<boolean | null>;
}
@Component({
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatChipsModule,
    LanguagesPickerComponent,
    QualitiesPickerComponent,
    SoftwareSkillsPickerComponent,
    CKEditorModule,
    MatCheckboxModule,
    WzbPlacePickerModule,
    NgxSliderModule,
    AsyncPipe,
    MatDatepickerModule,
    MatIcon,
    MatMomentDateModule,
  ],
  templateUrl: './job-detail.component.html',
  styleUrls: ['./job-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobDetailComponent implements OnInit, OnDestroy {
  jobOfferForm!: FormGroup<JobOfferForm>;

  readonly #store = inject(Store);
  readonly #breakpoints = inject(BreakpointsService);
  readonly #jobOfferWebservice = inject(JobOfferWebservice);
  readonly #contractWebservice = inject(ContractWebservice);
  readonly #degreeWebservice = inject(DegreeWebservice);
  readonly #modalDialog = inject(MatDialog);
  readonly #router = inject(Router);
  readonly #route = inject(ActivatedRoute);
  readonly #cdr = inject(ChangeDetectorRef);
  readonly #snackBar = inject(MatSnackBar);

  defaultFulltimeHours = 35;

  sliderOptions: Options = {
    showTicks: true,
    tickStep: 10,
    floor: 1,
    ceil: 70,
    animate: false,
    animateOnMove: false,
    translate: (value: number): string => {
      return `${value} K`;
    },
  };

  readonly pageTitle = computed(() => {
    return this.isEditable() ? 'Édition de fiche de poste' : 'Aperçu de la fiche de poste';
  });

  readonly navBack = {
    routerLink: FeaturesRoutingEnum.Jobs,
    wording: 'Revenir aux fiches de poste',
  };

  readonly #destroyRef = inject(DestroyRef);
  readonly Editor = InlineEditor;
  readonly missionsCkEditorConfig = { ...ckEditorConfig, placeholder: 'Description du poste *' };
  readonly OpsJobStatusEnum = OpsJobStatusEnum;
  readonly domainsList = OpsJobDomains;
  readonly effectiveDurationsLabelsMapping = [
    { key: OpsJobEffectiveDurationEnum.Fulltime, label: 'Temps Plein' },
    { key: OpsJobEffectiveDurationEnum.Parttime, label: 'Temps Partiel' },
  ];
  readonly experienceLevelsLabelsMapping = [
    { key: OpsJobExperienceEnum.Beginner, label: 'Débutant' },
    { key: OpsJobExperienceEnum.Confirmed, label: 'Confirmé' },
    { key: OpsJobExperienceEnum.Experimented, label: 'Expérimenté' },
  ];
  readonly contractDurationsLabelMapping = CONTRACT_DURATION_DISPLAY;

  get salaryRangeHasErrors(): boolean {
    return this.jobOfferForm.controls.salaryRange.untouched && !this.#jobHasSalaryRange();
  }

  readonly eventType = selectIfDefinedSig(this.#store, CompanyParticipationState.eventType);
  readonly isJobEditionAllowed = selectIfDefinedSig(this.#store, CompanyParticipationState.isJobEditionAllowed);
  readonly companyName = selectIfDefinedSig(this.#store, CompanyParticipationState.companyName);

  readonly #formStatus = signal<FormSubmitStatusEnum>(FormSubmitStatusEnum.PROCESSING);
  readonly readyToSubmit = signal<boolean>(false);
  readonly jobOfferStatus = signal<OpsJobStatusEnum | undefined>(OpsJobStatusEnum.STATUS_DRAFT);
  readonly #jobHasSalaryRange = signal<boolean>(false);
  readonly jobId = signal<string | null>(null);
  readonly contractTypes = signal<Contract[]>([]);

  readonly degrees = derivedAsync(() => this.#degreeWebservice.get(LocaleEnum.fr_FR));

  get displayContractDuration(): boolean {
    const contactsWithDuration = ['alternance', 'cdd', 'interimaire-1', 'stage', 'vie', 'these', 'freelance'];
    return contactsWithDuration.some((contractWithDurationId) => {
      if (this.jobOfferForm.controls.contractIds === undefined) {
        return false;
      }
      if (this.jobOfferForm.controls.contractIds.value === undefined) {
        return false;
      }
      return this.jobOfferForm.controls.contractIds.value?.includes(contractWithDurationId);
    });
  }

  get isMobile$(): Observable<boolean> {
    return this.#breakpoints.isMobile$;
  }

  readonly isEditable = computed(() => {
    console.log(
      'isEditable ?',
      Boolean(this.isJobEditionAllowed()) && (this.jobOfferStatus() === OpsJobStatusEnum.STATUS_DRAFT || !this.jobId())
    );
    return (
      Boolean(this.isJobEditionAllowed()) && (this.jobOfferStatus() === OpsJobStatusEnum.STATUS_DRAFT || !this.jobId())
    );
  });

  get isJobDating(): boolean {
    return this.eventType() === 'job_dating';
  }

  get textAreaMinRows$(): Observable<number> {
    return combineLatest([this.#breakpoints.isMobile$, this.#breakpoints.isWebOrTabletLandscape$]).pipe(
      map(([isMobile, isWebOrTabletLandscape]) => (isMobile ? 4 : isWebOrTabletLandscape ? 5 : 4))
    );
  }

  get isFormPristine(): boolean {
    return this.jobOfferForm.pristine;
  }

  get isFormValid(): boolean {
    return this.jobOfferForm.valid && !this.jobOfferForm.invalid;
  }

  canDeactivate(): FormStatusWhenLeavedEnum {
    return this.isFormPristine || !this.isEditable()
      ? FormStatusWhenLeavedEnum.pristine
      : this.isFormValid
        ? FormStatusWhenLeavedEnum.valid
        : FormStatusWhenLeavedEnum.invalid;
  }

  save(): void {
    return this.saveJobOffer();
  }

  ngOnInit(): void {
    this.#buildForm();
    this.#store.dispatch(new AddBreadcrumb(this.pageTitle()));
    this.#getJobOfferDataFromQueryParamAndBuildForm();
    this.#getContracts();

    if (history.state?.formStatus) {
      this.#formStatus.set(history.state.formStatus);
    }

    this.jobOfferForm.controls.name.valueChanges
      .pipe(
        takeUntilDestroyed(this.#destroyRef),
        map(() => {
          if (this.jobOfferForm.controls.missions.pristine) {
            this.jobOfferForm.controls.missions.setValue(this.#buildMissionsTemplate());
            this.jobOfferForm.controls.missions.markAsPristine();
          }
        })
      )
      .subscribe();

    this.jobOfferForm.controls.contractIds.valueChanges.pipe(takeUntilDestroyed(this.#destroyRef)).subscribe({
      next: () => {
        if (this.jobOfferForm.controls.missions.pristine) {
          this.jobOfferForm.controls.missions.setValue(this.#buildMissionsTemplate());
          this.jobOfferForm.controls.missions.markAsPristine();
        }
        this.jobOfferForm.controls.contractDuration.setValidators(
          this.displayContractDuration ? [Validators.required] : []
        );
        this.jobOfferForm.controls.contractDuration.updateValueAndValidity();
      },
    });
  }

  private getContractsTitles(): string {
    return (
      this.jobOfferForm.controls.contractIds?.value
        ?.map((id) => this.contractTypes().find((contract) => contract.id === id))
        .map((contract) => contract?.titleShort)
        .join('/') ?? ''
    );
  }

  saveJobOffer(): void {
    if (this.jobOfferForm.invalid || this.salaryRangeHasErrors) {
      this.#formStatus.set(FormSubmitStatusEnum.FAILED);
      return this.#openDefaultToaster(ToasterTypeEnum.WARNING);
    }

    this.jobOfferForm.markAllAsTouched();
    this.jobOfferForm.controls.locations.updateValueAndValidity();

    this.isMobile$
      .pipe(
        take(1),
        filter((isMobile) => !isMobile)
      )
      .subscribe({ next: () => window.scrollTo(0, 0) });

    const jobData = {
      ...this.jobOfferForm.value,
      salaryRange: {
        minimum: this.jobOfferForm.controls.salaryRange.value?.at(0) ?? 0,
        maximum: this.jobOfferForm.controls.salaryRange.value?.at(1) ?? 0,
      },
      openPositions: Number(this.jobOfferForm.controls.openPositions.value),
      effectiveHoursPerWeek: Number(this.jobOfferForm.controls.effectiveHoursPerWeek.value),
    };

    this.jobId() ? this.#updateJobOffer(toOpsJob(jobData)) : this.#createJobOffer(toOpsJob(jobData));
  }

  sendForValidation(triggeredFromBtn: boolean): void {
    const modalSubtitle = triggeredFromBtn
      ? "Confirmez-vous l'envoi pour validation de votre besoin de recrutement ?"
      : 'Votre offre a bien été enregistrée. Souhaitez-vous envoyer cette fiche de poste pour validation dès maintenant ?';
    this.#openModalSubmit(modalSubtitle)
      .afterClosed()
      .pipe(
        filter((result) => result.confirmed),
        switchMap(() => {
          const jobId = this.jobId();

          return jobId ? this.#jobOfferWebservice.sendForValidation(jobId) : EMPTY;
        }),
        catchError(() => {
          this.#modalDialog.open(InfoModalComponent, {
            data: {
              title: 'Erreur de soumission',
              subtitle: "L'offre n'a pas pu être soumise",
              navBtn: this.navBack,
            },
          });
          return EMPTY;
        })
      )
      .subscribe({
        next: () => {
          this.jobOfferStatus.set(OpsJobStatusEnum.STATUS_PENDING);
          this.#modalDialog.open(InfoModalComponent, {
            data: {
              subtitle: 'Wizbii étudie votre besoin de recrutement, vous allez recevoir une réponse d’ici 48h',
              navBtn: this.navBack,
            },
          });
        },
      });
  }

  setEffectiveHours(duration: OpsJobEffectiveDurationEnum): void {
    if (duration === OpsJobEffectiveDurationEnum.Parttime) {
      this.#setParttimeDefaultValue();
    } else if (duration === OpsJobEffectiveDurationEnum.Fulltime) {
      this.#setFulltimeDefaultValue();
    }
  }

  #setParttimeDefaultValue(): void {
    if (
      this.jobOfferForm.controls.effectiveHoursPerWeek.value === null ||
      Number(this.jobOfferForm.controls.effectiveHoursPerWeek.value) >= this.defaultFulltimeHours
    ) {
      this.jobOfferForm.controls.effectiveHoursPerWeek.setValue(null);
    }
  }

  #setFulltimeDefaultValue(): void {
    if (
      this.jobOfferForm.controls.effectiveHoursPerWeek.value === null ||
      Number(this.jobOfferForm.controls.effectiveHoursPerWeek.value) < this.defaultFulltimeHours
    ) {
      this.jobOfferForm.controls.effectiveHoursPerWeek.setValue(this.defaultFulltimeHours);
    }
  }

  #buildMissionsTemplate(): string {
    const contractsTitles = this.jobOfferForm.controls.contractIds?.value?.length
      ? this.getContractsTitles()
      : '[type de contrat]';
    const jobName = this.jobOfferForm.controls.name?.value ?? '[nom du poste]';
    const companyName = this.companyName();
    return `<p>La société ${companyName} recherche ${jobName} en ${contractsTitles}.</p><br><p>Vos missions seront :</p><ul><li>[Verbe d'action]</li><li>[Verbe d'action]</li><ul>`;
  }

  #setFormValues(job?: OpsJob): void {
    this.jobOfferForm.patchValue({
      name: job?.name ?? null,
      effectiveDuration: job?.effectiveDuration ?? null,
      effectiveHoursPerWeek: job?.effectiveHoursPerWeek ?? null,
      domain: job?.domain ?? null,
      experience: job?.experience ?? null,
      locations: job?.locations.map((location) => new City(location)) ?? [],
      missions: job?.missions ?? this.#buildMissionsTemplate(),
      qualities: job?.qualities ?? [],
      languages: job?.languages ?? [],
      softwareSkills: job?.softwareSkills ?? [],
      driversLicenceRequired: job?.driversLicenceRequired ?? null,
      degreeId: job?.degreeId ?? null,
      contractIds: job?.contractIds ?? [],
      contractDuration: job?.contractDuration ?? null,
      goodPoints: job?.goodPoints ?? null,
      startDate: job?.startDate ?? null,
      salaryRange: [job?.salaryRange?.minimum ?? 15, job?.salaryRange?.maximum ?? 50],
      openPositions: job?.openPositions ?? null,
      confidential: job?.confidential ?? false,
    });

    if (job) {
      this.jobOfferStatus.set(job.status);
    }

    if (!this.isEditable()) {
      console.log('disable form ?');
      this.jobOfferForm.disable();
      this.sliderOptions.disabled = true;
    }

    this.jobOfferForm.controls.effectiveDuration.valueChanges
      .pipe(filter((value): value is OpsJobEffectiveDurationEnum => !!value))
      .subscribe((value) => this.setEffectiveHours(value));
  }

  /* eslint-disable sonarjs/cognitive-complexity */
  #buildForm(): void {
    this.jobOfferForm = new FormGroup<JobOfferForm>({
      name: new FormControl(null, [Validators.required]),
      effectiveDuration: new FormControl(null, [Validators.required]),
      effectiveHoursPerWeek: new FormControl(undefined, [Validators.required]),
      domain: new FormControl(null, [Validators.required]),
      experience: new FormControl(null, this.isJobDating ? [] : [Validators.required]),
      locations: new FormControl(null, [Validators.required]),
      missions: new FormControl(null, [Validators.required]),
      qualities: new FormControl([]),
      languages: new FormControl([]),
      softwareSkills: new FormControl([]),
      driversLicenceRequired: new FormControl(null, [Validators.required]),
      degreeId: new FormControl(null, this.isJobDating ? [] : [Validators.required]),
      contractIds: new FormControl([], [Validators.required]),
      contractDuration: new FormControl(null),
      goodPoints: new FormControl(null),
      startDate: new FormControl(null, [Validators.required]),
      salaryRange: new FormControl([15, 50], [Validators.required]),
      openPositions: new FormControl(null, [Validators.required]),
      confidential: new FormControl(false),
    });
  }

  #getJobOfferDataFromQueryParamAndBuildForm(): void {
    this.jobId.set(this.#route.snapshot.paramMap.get('jobId'));
    const jobId = this.jobId();
    if (jobId) {
      this.#jobOfferWebservice.get(jobId).subscribe({
        next: (jobOffer) => {
          this.#setFormValues(jobOffer);
          this.jobOfferStatus.set(jobOffer.status);
          this.#jobHasSalaryRange.set(!!jobOffer.salaryRange.minimum && !!jobOffer.salaryRange.maximum);
          this.#cdr.markForCheck();

          if (this.jobOfferForm.valid) {
            this.readyToSubmit.set(true);
          }
        },
      });
    } else {
      this.#setFormValues();
    }
  }

  #updateJobOffer(job: OpsJob): void {
    const jobId = this.jobId();
    if (jobId) {
      this.#jobOfferWebservice.update(jobId, job).subscribe({
        next: () => {
          this.#markAsSavedAndReadyToSubmit();
          return this.sendForValidation(false);
        },
        error: () => this.#openDefaultToaster(ToasterTypeEnum.ERROR),
      });
    }
  }

  #createJobOffer(job: OpsJob): void {
    this.#jobOfferWebservice.create(job).subscribe({
      next: (jobOffer) => {
        this.jobId.set(jobOffer.id);
        this.#router.navigate(['/', FeaturesRoutingEnum.Jobs, jobOffer.id], {
          state: { formStatus: FormSubmitStatusEnum.SUCCESS },
        });
        this.#markAsSavedAndReadyToSubmit();
        return this.sendForValidation(false);
      },
      error: () => this.#openDefaultToaster(ToasterTypeEnum.ERROR),
    });
  }

  #openModalSubmit(subtitle: string): MatDialogRef<ConfirmModalComponent> {
    return this.#modalDialog.open(ConfirmModalComponent, {
      data: {
        reverseOrder: true,
        icon: { name: 'warning', color: '#E3BA1F' },
        subtitle: subtitle,
        choiceWording: { confirm: 'Envoyer pour validation', cancel: 'Revenir à mon offre' },
      },
    });
  }

  #getContracts(): void {
    this.#contractWebservice
      .get(LocaleEnum.fr_FR)
      ?.pipe(take(1))
      .subscribe({ next: (contracts) => this.contractTypes.set(contracts) });
  }

  #markAsSavedAndReadyToSubmit() {
    this.readyToSubmit.set(true);
    this.#formStatus.set(FormSubmitStatusEnum.SUCCESS);
    this.jobOfferForm.markAsPristine();
  }

  #openDefaultToaster(type: ToasterTypeEnum): void {
    this.#snackBar.openFromComponent(ToasterComponent, {
      data: { type },
    });
  }

  ngOnDestroy(): void {
    this.#store.dispatch(new RemoveCustomBreadcrumbs());
  }

  onDescriptionChange(_event: ChangeEvent): void {
    this.jobOfferForm.controls.missions.markAsDirty();
  }
}
