<!-- eslint-disable @angular-eslint/template/no-call-expression -->
<div class="logo-container">
  @if (companyLogoUrl(); as companyLogoUrl) {
    <img [src]="companyLogoUrl" [alt]="'Logo de l\'entreprise ' + companyName()" />
  }
  @if (!companyLogoUrl) {
    <div class="logo-container__placeholder">
      <mat-icon svgIcon="image" aria-hidden="true" focusable="false" />
    </div>
  }
</div>
