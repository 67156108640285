export class GetBreadcrumbs {
  static type = '[Breadcrumbs] Get the breadcrumbs';
}

export class AddBreadcrumb {
  static readonly type = '[Breadcrumbs] Add a breadcrumb';
  constructor(readonly label: string) {}
}

export class RemoveCustomBreadcrumbs {
  static readonly type = '[Breadcrumbs] Remove custome breadcrumbs';
  constructor() {}
}
