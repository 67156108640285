import { CompanyParticipation } from '@models/company-participation';

export class SetCompanyParticipation {
  static readonly type = '[CompanyParticipation] Set Company Participation';
  constructor(public companyParticipation: CompanyParticipation) {}
}

export class SetCompanyName {
  static readonly type = '[CompanyParticipation] Set Company Name';
  constructor(public name: string) {}
}

export class SetCompanyLogo {
  static readonly type = '[CompanyParticipation] Set Company Logo';
  constructor(
    public logoUrl: string,
    public logoFileId: string
  ) {}
}
