import { Candidate } from '@models/candidate';
import { JobEvaluation } from '@models/job-evaluation';

enum JobNotationScoringEnum {
  yes,
  maybe,
  no,
}

export enum CandidateStatusScoringEnum {
  yes,
  maybe,
  onWaitingList,
  didNotApply,
  absent,
  no,
}

export function sortByNotation(jobA: JobEvaluation, jobB: JobEvaluation): number {
  return JobNotationScoringEnum[jobA.evaluation] - JobNotationScoringEnum[jobB.evaluation];
}

export function sortByStatus(a: Candidate, b: Candidate): number {
  return CandidateStatusScoringEnum[a.globalNotation] - CandidateStatusScoringEnum[b.globalNotation];
}
