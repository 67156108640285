import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@environment';
import { OpsJob } from '@wizbii/utils/models';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class JobOfferWebservice {
  readonly #httpClient = inject(HttpClient);

  private readonly baseUrl = `${environment.api.cvBook}/api/job`;

  getCompanyJobOffers(): Observable<OpsJob[]> {
    return this.#httpClient.get<OpsJob[]>(`${this.baseUrl}/`);
  }

  get(jobId: string): Observable<OpsJob> {
    return this.#httpClient.get<OpsJob>(`${this.baseUrl}/${jobId}`);
  }

  create(jobOffer: OpsJob): Observable<OpsJob> {
    return this.#httpClient.post<OpsJob>(this.baseUrl, jobOffer);
  }

  update(jobId: string, jobOffer: OpsJob): Observable<OpsJob> {
    return this.#httpClient.put<OpsJob>(`${this.baseUrl}/${jobId}`, jobOffer);
  }

  sendForValidation(jobId: string): Observable<void> {
    return this.#httpClient.post<void>(`${this.baseUrl}/${jobId}/submit`, jobId);
  }
}
