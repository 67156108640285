<!-- eslint-disable @angular-eslint/template/no-inline-styles -->
<div class="confirm-modal">
  @if (data.icon; as icon) {
    <mat-icon
      class="confirm-modal__icon"
      [svgIcon]="icon.name"
      [style]="iconStyle"
      aria-hidden="true"
      focusable="false"
    />
  }
  @if (data.title; as title) {
    <h1 mat-dialog-title>{{ title }}</h1>
  }
  @if (data.subtitle; as subtitle) {
    <p mat-dialog-content>{{ subtitle }}</p>
  }
  <div mat-dialog-actions>
    <button
      class="btn-clean btn-default-white"
      (click)="data.reverseOrder ? onCancel() : onConfirm()"
      type="button"
      mat-button
    >
      {{ data.reverseOrder ? cancelWording : confirmWording }}
    </button>
    <button
      class="btn-clean btn-default-primary"
      (click)="data.reverseOrder ? onConfirm() : onCancel()"
      type="button"
      mat-button
    >
      {{ data.reverseOrder ? confirmWording : cancelWording }}
    </button>
  </div>
</div>
