export const CONTRACT_DURATION_DISPLAY = [
  { label: '< 1 mois', key: 0 },
  { label: '1 mois', key: 1 },
  { label: '2 mois', key: 2 },
  { label: '3 mois', key: 3 },
  { label: '4 mois', key: 4 },
  { label: '5 mois', key: 5 },
  { label: '6 mois', key: 6 },
  { label: '7 mois', key: 7 },
  { label: '8 mois', key: 8 },
  { label: '9 mois', key: 9 },
  { label: '10 mois', key: 10 },
  { label: '11 mois', key: 11 },
  { label: '12 mois', key: 12 },
  { label: '> 12 mois', key: 13 },
];
