import { AbstractControl, ValidationErrors } from '@angular/forms';
import { CountryCode, isValidPhoneNumber } from 'libphonenumber-js/max';

function isEmptyInputValue(value: string | string[]): boolean {
  // we don't check for string here so it also works with arrays
  return !value || value.length === 0;
}

export class WizValidators {
  static noWhitespace(control: AbstractControl): ValidationErrors | null {
    const isWhitespace =
      !!control.value && typeof control.value === 'string' ? control.value.trim().length === 0 : false;
    return !isWhitespace ? null : { whitespace: true };
  }

  static phone(control: AbstractControl): ValidationErrors | null {
    if (isEmptyInputValue(control.value)) {
      return null;
    }

    const frCountriesCodes: CountryCode[] = ['FR', 'BL', 'GF', 'GP', 'MF', 'MQ', 'NC', 'PF', 'PM', 'RE', 'WF', 'YT'];
    return frCountriesCodes.some((countryCode) => isValidPhoneNumber(control.value.replace(/ /g, ''), countryCode))
      ? null
      : { phone: true };
  }
}
