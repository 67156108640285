import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { environment } from '@environment';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { ImaginarySrcPipe } from '@wizbii-utils/angular/pipes';

@Component({
  standalone: true,
  imports: [MatIcon, RouterModule, ImaginarySrcPipe],
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotFoundComponent {
  readonly FeaturesRoutingEnum = FeaturesRoutingEnum;
  readonly imgWidth = 680;
  readonly imgSrc = `${environment.wizbiiImages}/NotFound/not-found.png`;
}
