import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  OnDestroy,
  OnInit,
  inject,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmModalComponent } from '@commons/components/modals/confirm-modal/confirm-modal.component';
import { ToasterComponent } from '@commons/components/toaster/toaster.component';
import { CanComponentDeactivate, FormStatusWhenLeavedEnum } from '@commons/guards/form/form.guard';
import { BreakpointsService } from '@core/services/breakpoints/breakpoints.service';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { RecruiterData } from '@models/recruiter-data';
import { ToasterTypeEnum } from '@models/toaster-config';
import { Store } from '@ngxs/store';
import { FormSubmitStatusEnum } from '@shared/form-status.enum';
import { WizValidators } from '@shared/validators/validators';
import { AddBreadcrumb, RemoveCustomBreadcrumbs } from '@stores/breadcrumb/breadcrumb.actions';
import { RecruiterWebservice } from '@webservices/cv-book-api/recruiter/recruiter.webservice';
import { Observable } from 'rxjs';

export interface RecruiterForm {
  firstname: FormControl<string | null>;
  lastname: FormControl<string | null>;
  phone: FormControl<string | null>;
  mail: FormControl<string | null>;
  position: FormControl<string | null>;
}

@Component({
  standalone: true,
  imports: [MatFormFieldModule, MatInput, ReactiveFormsModule],
  templateUrl: './recruiter-detail.component.html',
  styleUrls: ['./recruiter-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecruiterDetailComponent implements OnInit, OnDestroy, CanComponentDeactivate {
  recruiterForm!: FormGroup;
  initialFormValues: any;

  readonly formStatus = signal<FormSubmitStatusEnum>(FormSubmitStatusEnum.PROCESSING);
  readonly #readyToSubmit = signal<boolean>(false);
  readonly #recruiterId = signal<string | null>(null);

  readonly pageTitle = 'Édition de recruteur';

  get firstname(): FormControl<string> {
    return this.recruiterForm?.get('firstname') as FormControl;
  }

  get lastname(): FormControl<string> {
    return this.recruiterForm?.get('lastname') as FormControl;
  }

  get phone(): FormControl<string> {
    return this.recruiterForm?.get('phone') as FormControl;
  }

  get mail(): FormControl<string> {
    return this.recruiterForm?.get('mail') as FormControl;
  }

  get position(): FormControl<string> {
    return this.recruiterForm?.get('position') as FormControl;
  }

  get isMobile$(): Observable<boolean> {
    return this.#breakpoints.isMobile$;
  }

  get isFormPristine(): boolean {
    return this.recruiterForm.pristine;
  }

  get isFormValid(): boolean {
    return this.recruiterForm.valid && !this.recruiterForm.invalid;
  }

  canDeactivate(): FormStatusWhenLeavedEnum {
    return this.isFormPristine
      ? FormStatusWhenLeavedEnum.pristine
      : this.isFormValid
        ? FormStatusWhenLeavedEnum.valid
        : FormStatusWhenLeavedEnum.invalid;
  }

  readonly #destroyRef = inject(DestroyRef);
  readonly #store = inject(Store);
  readonly #recruiterWebservice = inject(RecruiterWebservice);
  readonly #breakpoints = inject(BreakpointsService);
  readonly #modalDialog = inject(MatDialog);
  readonly #route = inject(ActivatedRoute);
  readonly #router = inject(Router);
  readonly #cdr = inject(ChangeDetectorRef);
  readonly #snackBar = inject(MatSnackBar);

  ngOnInit(): void {
    this.#store.dispatch(new AddBreadcrumb(this.pageTitle));
    this._getRecruiterDataFromQueryParam();
  }

  reset(): void {
    this.confirmReset()
      .afterClosed()
      .subscribe({
        next: (result) => {
          if (result.confirmed) {
            this.recruiterForm?.reset(this.initialFormValues);
            this.recruiterForm?.markAsPristine();
          }
        },
      });
  }

  confirmReset(): MatDialogRef<ConfirmModalComponent> {
    return this.#modalDialog.open(ConfirmModalComponent, {
      data: {
        title: "Le contenu n'est pas sauvegardé",
        subtitle: 'Êtes-vous sûr(e) de vouloir annuler vos modifications ?',
      },
    });
  }

  submit(): void {
    if (this.formStatus() !== FormSubmitStatusEnum.READY) {
      return;
    }

    if (this.recruiterForm.invalid || !this.recruiterForm.valid) {
      this.formStatus.set(FormSubmitStatusEnum.FAILED);
      return this._openDefaultToaster(ToasterTypeEnum.WARNING);
    }

    this.formStatus.set(FormSubmitStatusEnum.PROCESSING);

    const recruiterData: RecruiterData = {
      firstName: this.firstname.value,
      lastName: this.lastname.value,
      phone: this.phone.value,
      mail: this.mail.value,
      position: this.position.value,
    };

    this.#recruiterId() ? this._updateRecruiter(recruiterData) : this._createRecruiter(recruiterData);
  }

  save(): void {
    return this.submit();
  }

  private _createRecruiter(recruiterData: RecruiterData): void {
    this.#recruiterWebservice.create(recruiterData)?.subscribe({
      next: (_recruiter) => {
        this.#router.navigate(['/', FeaturesRoutingEnum.Recruiters], {
          state: { formStatus: FormSubmitStatusEnum.SUCCESS },
        });
        this._markAsSavedAndReadyToSubmit();
        this._openDefaultToaster(ToasterTypeEnum.SUCCESS);
      },
      error: (err) => {
        return this.#snackBar.openFromComponent(ToasterComponent, {
          data: { message: err?.error?.message, type: ToasterTypeEnum.ERROR },
        });
      },
    });
  }

  private _updateRecruiter(recruiter: RecruiterData): void {
    const recruiterId = this.#recruiterId();
    if (recruiterId) {
      this.#recruiterWebservice.update({ id: recruiterId, ...recruiter })?.subscribe({
        next: () => {
          this._markAsSavedAndReadyToSubmit();
          this._openDefaultToaster(ToasterTypeEnum.SUCCESS);
        },
        error: () => this._openDefaultToaster(ToasterTypeEnum.ERROR),
      });
    }
  }

  private _openDefaultToaster(type: ToasterTypeEnum): void {
    this.#snackBar.openFromComponent(ToasterComponent, {
      data: { type },
    });
  }

  private _buildForm(recruiter?: RecruiterData): void {
    this.recruiterForm = new FormGroup<RecruiterForm>({
      firstname: new FormControl(recruiter?.firstName ?? null, [Validators.required]),
      lastname: new FormControl(recruiter?.lastName ?? null, [Validators.required]),
      phone: new FormControl(recruiter?.phone ?? null, [WizValidators.phone]),
      mail: new FormControl(recruiter?.mail ?? null),
      position: new FormControl(recruiter?.position ?? null),
    });

    this.initialFormValues = this.recruiterForm.value;

    this.recruiterForm.valueChanges
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe({ next: () => this.formStatus.set(FormSubmitStatusEnum.READY) });
  }

  private _getRecruiterDataFromQueryParam(): void {
    this.#recruiterId.set(this.#route.snapshot.paramMap.get('recruiterId'));
    const recruiterId = this.#recruiterId();
    if (recruiterId) {
      this.#recruiterWebservice.getRecruiter(recruiterId)?.subscribe({
        next: (recruiter) => {
          this._buildForm(recruiter);
          this.#cdr.markForCheck();
        },
      });
    } else {
      this._buildForm();
    }
  }

  private _markAsSavedAndReadyToSubmit() {
    this.#readyToSubmit.set(true);
    this.formStatus.set(FormSubmitStatusEnum.SUCCESS);
    this.recruiterForm.markAsPristine();
  }

  ngOnDestroy(): void {
    this.#store.dispatch(new RemoveCustomBreadcrumbs());
  }
}
