import { Breadcrumb } from '@models/breadcrumb';
import { Menu, MenuItem } from '@models/menu';

export function getMenuItems(pathSegments: string[], menu: Menu, menuItems: MenuItem[]): MenuItem[] {
  const menuItem = menu[pathSegments[0]];

  if (!menuItem || pathSegments.length <= 0) {
    return menuItems;
  }

  menuItems.push(menuItem);

  if (!menuItem.menuItems) {
    return menuItems;
  }

  return getMenuItems(pathSegments.slice(1), menuItem.menuItems, menuItems);
}

export function getBreadcrumbsFromUrl(url: string, menu: Menu): Breadcrumb[] {
  const urlSegments = url.split('?')[0].split('/');
  urlSegments.splice(0, 1);

  const menuItems = getMenuItems(urlSegments, menu, []);

  return menuItems.map((item, i) => {
    const previousCrumbsPaths = menuItems.slice(0, i).map((crumb) => crumb.path);
    return { label: item.label, route: [...previousCrumbsPaths, item.path] };
  });
}
