import { Injectable, inject } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { selectIfDefinedSig } from '@core/utils/selects.operators';
import { environment } from '@environment';
import { Store } from '@ngxs/store';
import { SetToken } from '@stores/auth/auth.actions';
import { AuthState } from '@stores/auth/auth.state';
import { SetCandidates } from '@stores/candidates/candidates.actions';
import { SetCompanyParticipation } from '@stores/company-participation/company-participation.actions';
import { CvBookWebservice } from '@webservices/cv-book-api/cv-book.webservice';
import { CookieService } from 'ngx-cookie-service';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  readonly #store = inject(Store);
  readonly #cookieService = inject(CookieService);
  readonly #cvBookWebservice = inject(CvBookWebservice);

  readonly isAuth = selectIfDefinedSig(this.#store, AuthState.isAuth);
  readonly token = selectIfDefinedSig(this.#store, AuthState.token);

  setTokenInStore(token: string): void {
    this.#store.dispatch(new SetToken(token));

    combineLatest([this.#cvBookWebservice.getCompanyParticipation(), this.#cvBookWebservice.getCandidatesList()]).pipe(
      map(([companyParticipation, candidatesList]) => {
        const stateToken = this.token();

        if (stateToken) {
          this.#store.dispatch(new SetCompanyParticipation(companyParticipation));
          this.#store.dispatch(new SetCandidates(candidatesList));
        }
      })
    );
  }

  getTokenFromStore(): Observable<string | undefined> {
    return toObservable(this.token);
  }

  getTokenFromCookies(): string {
    return this.#cookieService.get('token');
  }

  setTokenCookie(token: string): void {
    const cookieDomain = this.getCookieDomain();
    const msIn390Days = 1000 * 3600 * 24 * 390;
    const expiry = new Date(Date.now() + msIn390Days);

    this.#cookieService.delete('token');

    this.#cookieService.set(
      'token',
      token,
      expiry,
      '/',
      cookieDomain,
      cookieDomain !== 'localhost',
      cookieDomain === 'localhost' ? 'Lax' : 'None'
    );
  }

  private getCookieDomain(): string {
    const cookieSubDomain = ['', ...document.location.hostname.split('.').slice(-2)].join('.');
    return cookieSubDomain === '.localhost' ? 'localhost' : `.${environment.apiDomain}`;
  }
}
