import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { selectIfDefinedSig } from '@core/utils/selects.operators';
import { Store } from '@ngxs/store';
import { CompanyParticipationState } from '@stores/company-participation/company-participation.state';
import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class IsSourcingOpenGuard {
  readonly #store = inject(Store);
  readonly #isJobEditionAllowed = selectIfDefinedSig(this.#store, CompanyParticipationState.isJobEditionAllowed);

  canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean> {
    return of(this.#isJobEditionAllowed() !== undefined);
  }
}
