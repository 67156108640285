export enum ToasterTypeEnum {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
}

export interface ToasterConfig {
  type: ToasterTypeEnum;
  message?: string;
  duration?: number;
}
