import { Component, input } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { Breadcrumb } from '@models/breadcrumb';

@Component({
  standalone: true,
  selector: 'app-breadcrumb',
  imports: [MatIcon, RouterModule],
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent {
  readonly breadcrumbs = input<Breadcrumb[]>([]);
}
