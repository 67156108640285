import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, inject, signal } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { BreakpointsService } from '@core/services/breakpoints/breakpoints.service';
import { selectIfDefinedSig } from '@core/utils/selects.operators';
import { RecruiterCardComponent } from '@domains/recruiter/recruiter-card/recruiter-card.component';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { RecruitersRoutingEnum } from '@features/recruiters/recruiters-routing.enum';
import { RecruiterData } from '@models/recruiter-data';
import { Store } from '@ngxs/store';
import { CompanyParticipationState } from '@stores/company-participation/company-participation.state';
import { RecruiterWebservice } from '@webservices/cv-book-api/recruiter/recruiter.webservice';
import { Observable } from 'rxjs';

@Component({
  templateUrl: './recruiters.component.html',
  styleUrls: ['./recruiters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatIcon, RecruiterCardComponent, RouterModule, AsyncPipe],
})
export class RecruitersComponent implements OnInit {
  readonly recruiters = signal<RecruiterData[]>([]);

  readonly #recruiterWebservice = inject(RecruiterWebservice);
  readonly #breakpoints = inject(BreakpointsService);
  readonly #store = inject(Store);

  readonly FeaturesRoutingEnum = FeaturesRoutingEnum;
  readonly RecruitersRoutingEnum = RecruitersRoutingEnum;

  readonly eventId = selectIfDefinedSig(this.#store, CompanyParticipationState.eventId);

  get isMobile$(): Observable<boolean> {
    return this.#breakpoints.isMobile$;
  }

  get isTablet$(): Observable<boolean> {
    return this.#breakpoints.isTablet$;
  }

  ngOnInit(): void {
    this.getRecruiters();
  }

  private getRecruiters() {
    this.#recruiterWebservice.getRecruitersList().subscribe({
      next: (recruiters) => {
        this.recruiters.set(recruiters);
      },
    });
  }
}
