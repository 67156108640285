<div class="modal">
  @if (data.title; as title) {
    <h1 mat-dialog-title>{{ title }}</h1>
  }
  @if (data.subtitle; as subtitle) {
    <p mat-dialog-content>{{ subtitle }}</p>
  }
  @if (data.navBtn?.routerLink; as routerLink) {
    <div mat-dialog-actions>
      <a
        class="href-clean btn-default btn-clean btn-emphasis-primary"
        [routerLink]="routerLink"
        (click)="dialogRef.close()"
        >{{ data.navBtn?.wording ?? 'Retour' }}</a
      >
    </div>
  }
</div>
