<!-- eslint-disable @angular-eslint/template/no-call-expression -->
<article>
  <header class="header">
    <h2 class="header__page-title">Recruteurs présents</h2>

    <ul class="form-controls ul-clean">
      <li class="form-controls__btn">
        <a
          class="href-clean btn-emphasis-primary btn-create-action"
          [routerLink]="['/', FeaturesRoutingEnum.Recruiters, RecruitersRoutingEnum.Create]"
        >
          <mat-icon
            class="btn-emphasis-primary__icon--left btn-create-action__icon"
            matPrefix
            svgIcon="add"
            aria-hidden="true"
            focusable="false"
          />
          <span class="btn-create-action__legend">Ajouter un recruteur</span>
        </a>
      </li>
    </ul>
  </header>

  <ul class="recruiters ul-clean">
    @for (recruiter of recruiters(); track recruiter) {
      <li class="recruiters__item">
        @if (eventId(); as eventId) {
          <app-recruiter-card [recruiter]="recruiter" [eventId]="eventId" />
        }
      </li>
    }
  </ul>
</article>
