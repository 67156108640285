<section class="not-found">
  <div class="not-found__content">
    <div class="not-found__content__left">
      <h1 class="not-found__content__left__title">404</h1>
      <h2 class="not-found__content__left__subtitle">Oooups&nbsp;!<br />Page non trouvée</h2>
      <p class="not-found__content__left__description">Quelque chose ne s'est pas passé comme dans le mode d'emploi.</p>
      <a class="href-clean btn-emphasis-primary" [routerLink]="['/', FeaturesRoutingEnum.Company]"
        >Retour à votre espace
        <mat-icon class="btn-emphasis-primary__icon--right" svgIcon="arrow-right" aria-hidden="true" focusable="false"
      /></a>
    </div>
  </div>

  <img
    class="not-found__img"
    [src]="imgSrc | imaginarySrc: 1 : imgWidth"
    [srcset]="imgSrc | imaginarySrc: 4 : imgWidth"
    alt=""
    aria-hidden="true"
  />
</section>
