import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@environment';
import { RecruiterData } from '@models/recruiter-data';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class RecruiterWebservice {
  readonly #httpClient = inject(HttpClient);

  private readonly baseUrl = `${environment.api.cvBook}/api/recruiter`;

  getRecruitersList(): Observable<RecruiterData[]> {
    return this.#httpClient.get<RecruiterData[]>(this.baseUrl);
  }

  getRecruiter(recruiterId: string): Observable<RecruiterData> {
    return this.#httpClient.get<RecruiterData>(`${this.baseUrl}/${recruiterId}`);
  }

  create(recruiter: RecruiterData): Observable<RecruiterData> {
    return this.#httpClient.post<RecruiterData>(this.baseUrl, recruiter);
  }

  update(recruiter: RecruiterData): Observable<RecruiterData> {
    return this.#httpClient.put<RecruiterData>(`${this.baseUrl}/${recruiter?.id}`, recruiter);
  }

  delete(recruiterId: string): Observable<RecruiterData> {
    return this.#httpClient.delete<RecruiterData>(`${this.baseUrl}/${recruiterId}`);
  }

  addRecruiterToEvent(recruiterId: string, eventId: string): Observable<void> {
    return this.#httpClient.post<void>(`${this.baseUrl}/${recruiterId}/participation/${eventId}`, {});
  }

  removeRecruiterFromEvent(recruiterId: string, eventId: string): Observable<void> {
    return this.#httpClient.delete<void>(`${this.baseUrl}/${recruiterId}/participation/${eventId}`);
  }
}
