<!-- eslint-disable @angular-eslint/template/no-call-expression -->
@if (recruiter(); as recruiter) {
  <div class="recruiter">
    <a class="recruiter__description" [routerLink]="['/', FeaturesRoutingEnum.Recruiters, recruiter.id]">
      <h3 class="recruiter__description__title">{{ recruiter.firstName }} {{ recruiter.lastName }}</h3>
      @if (recruiter.position) {
        <p class="chip-light--grey">{{ recruiter.position }}</p>
      }
    </a>
    <div class="recruiter__actions">
      <div class="recruiter__actions__status">
        <mat-slide-toggle [checked]="checked()" (change)="recruiterStatusChanged($event)" hideIcon>
          {{ recruiterStatusLabel }}
        </mat-slide-toggle>
      </div>
      <a
        class="href-clean btn-emphasis-ghost recruiter__actions__btn"
        [routerLink]="['/', FeaturesRoutingEnum.Recruiters, recruiter.id]"
      >
        <mat-icon
          class="btn-emphasis-ghost__icon--left"
          svgIcon="pen"
          aria-hidden="true"
          focusable="false"
        />Modifier</a
      >
    </div>
  </div>
}
