<!-- eslint-disable @angular-eslint/template/no-call-expression -->
<mat-form-field class="input-simple custom-chips" appearance="outline">
  <mat-label>Logiciels</mat-label>
  <mat-chip-grid [disabled]="disabled()" #softwareList>
    @for (softwareSkill of selectedSoftwareSkills; track softwareSkill) {
      <mat-chip-row (removed)="remove(softwareSkill)">
        {{ softwareSkill }}
        @if (!disabled()) {
          <button class="button-remove" type="button" matChipRemove>
            <mat-icon class="button-remove__icon" svgIcon="cross" />
          </button>
        }
      </mat-chip-row>
    }
    <input
      [placeholder]="disabled() || selectedSoftwareSkills.length ? '' : 'Ajouter un logiciel et appuyer sur Entrée'"
      [matChipInputFor]="softwareList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      [matChipInputAddOnBlur]="true"
      (matChipInputTokenEnd)="add($event)"
    />
  </mat-chip-grid>
</mat-form-field>
