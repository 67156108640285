import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ClientEnum, ClientLogos } from '@models/client.enum';
import { CompanyParticipation } from '@models/company-participation';
import { EventOwner } from '@models/event-owner';
import { Action, NgxsOnInit, Selector, State, StateContext, StateToken } from '@ngxs/store';
import {
  SetCompanyLogo,
  SetCompanyName,
  SetCompanyParticipation,
} from '@stores/company-participation/company-participation.actions';
import { CvBookWebservice } from '@webservices/cv-book-api/cv-book.webservice';
import { FileWebservice } from '@wizbii-utils/angular/webservices';
import { OpsRecruiter } from '@wizbii/models';
import dayjs from 'dayjs';

export type CompanyParticipationStateModel = CompanyParticipation | undefined;
export const CompanyParticipationStateToken = new StateToken<CompanyParticipationStateModel>('participation');

@State({
  name: CompanyParticipationStateToken,
  defaults: null,
})
@Injectable()
export class CompanyParticipationState implements NgxsOnInit {
  @Selector([CompanyParticipationStateToken])
  static companyParticipation(state: CompanyParticipationStateModel): CompanyParticipationStateModel {
    return state;
  }

  @Selector([CompanyParticipationStateToken])
  static companyId(state: CompanyParticipationStateModel): string | undefined {
    return state?.companyId;
  }

  @Selector([CompanyParticipationStateToken])
  static clientId(state: CompanyParticipationStateModel): ClientEnum | undefined {
    return state?.clientId;
  }

  @Selector([CompanyParticipationState.clientId])
  static clientLogo(clientId: ClientEnum): string | undefined {
    return clientId ? ClientLogos[clientId] : undefined;
  }

  @Selector([CompanyParticipationStateToken])
  static companyName(state: CompanyParticipationStateModel): string | undefined {
    return state?.companyName;
  }

  @Selector([CompanyParticipationStateToken])
  static companyLogoFileUrl(state: CompanyParticipationStateModel): string | undefined {
    return state?.companyLogoFileUrl;
  }

  @Selector([CompanyParticipationStateToken])
  static eventId(state: CompanyParticipationStateModel): string | undefined {
    return state?.eventId;
  }

  @Selector([CompanyParticipationStateToken])
  static eventName(state: CompanyParticipationStateModel): string | undefined {
    return state?.eventName;
  }

  @Selector([CompanyParticipationStateToken])
  static eventCity(state: CompanyParticipationStateModel): string | undefined {
    return state?.eventLocation?.city;
  }

  @Selector([CompanyParticipationStateToken])
  static eventType(state: CompanyParticipationStateModel): string | undefined {
    return state?.eventType;
  }

  @Selector([CompanyParticipationStateToken])
  static eventOwnerEmail(state: CompanyParticipationStateModel): string | undefined {
    return state?.eventOwner?.eventOwnerEmail;
  }

  @Selector([CompanyParticipationStateToken])
  static cvbookImageFileUrl(state: CompanyParticipationStateModel): string | undefined {
    return state?.cvbookImageFileUrl;
  }

  @Selector([CompanyParticipationStateToken])
  static recruiters(state: CompanyParticipationStateModel): OpsRecruiter[] | undefined {
    return state?.recruiters;
  }

  @Selector([CompanyParticipationStateToken])
  static recruitersNames(state: CompanyParticipationStateModel): string | undefined {
    return state?.recruiters
      ?.map((recruiter) => `${recruiter.firstName} ${recruiter.lastName.toUpperCase()}`)
      .join(' - ');
  }

  @Selector([CompanyParticipationStateToken])
  static recruitersCommitteesNames(state: CompanyParticipationStateModel): string | undefined {
    return state?.recruiters
      ?.filter((recruiter) => !!state?.committees.some((committee) => committee.recruitersIds.includes(recruiter.id)))
      ?.map((recruiter) => `${recruiter.firstName} ${recruiter.lastName.toUpperCase()}`)
      .join(' - ');
  }

  @Selector([CompanyParticipationStateToken])
  static eventOwner(state: CompanyParticipationStateModel): EventOwner | undefined {
    return state?.eventOwner;
  }

  @Selector([CompanyParticipationStateToken])
  static companyJobsIds(state: CompanyParticipationStateModel): string[][] {
    return [].concat.apply(state?.committees.map((committee) => committee.jobsIds));
  }

  @Selector([CompanyParticipationStateToken])
  static isJobEditionAllowed(state: CompanyParticipationStateModel): boolean | undefined {
    if (!state) {
      return undefined;
    }

    const earliestDateToCloseJobCreation = state?.sourcingEndDate ?? state?.scheduleStart;

    return dayjs(earliestDateToCloseJobCreation).isAfter(dayjs());
  }

  @Selector([CompanyParticipationStateToken])
  static isEventPast(state: CompanyParticipationStateModel): boolean | undefined {
    if (!state) {
      return undefined;
    }

    return dayjs(state?.scheduleEnd).isBefore(dayjs());
  }

  @Action(SetCompanyParticipation)
  setCompaniesParticipation(
    ctx: StateContext<CompanyParticipationStateModel>,
    { companyParticipation }: SetCompanyParticipation
  ): void {
    ctx.patchState(companyParticipation);

    if (companyParticipation.cvbookImageFileId) {
      this.fileWebService.getFileUrl(companyParticipation.cvbookImageFileId).subscribe({
        next: (url) => {
          ctx.patchState({ ...companyParticipation, cvbookImageFileUrl: url });
        },
      });
    }

    if (companyParticipation.companyLogoFileId) {
      this.fileWebService.getFileUrl(companyParticipation.companyLogoFileId).subscribe({
        next: (url) => {
          ctx.patchState({ ...companyParticipation, companyLogoFileUrl: url });
        },
      });
    }
  }

  @Action(SetCompanyName)
  setCompanyName(ctx: StateContext<CompanyParticipationStateModel>, { name }: SetCompanyName): void {
    ctx.patchState({ companyName: name });
  }

  @Action(SetCompanyLogo)
  setCompanyLogo(ctx: StateContext<CompanyParticipationStateModel>, { logoUrl, logoFileId }: SetCompanyLogo): void {
    ctx.patchState({ companyLogoFileUrl: logoUrl, companyLogoFileId: logoFileId });
  }

  constructor(
    private readonly cvBookWebservice: CvBookWebservice,
    private readonly titleService: Title,
    private readonly fileWebService: FileWebservice
  ) {}

  ngxsOnInit(ctx: StateContext<CompanyParticipationStateModel>): void {
    this.cvBookWebservice.getCompanyParticipation().subscribe({
      next: (companyParticipation: CompanyParticipation) => {
        ctx.dispatch(new SetCompanyParticipation(companyParticipation));
        this.titleService.setTitle(`CV Book - ${companyParticipation.companyName}`);
      },
    });
  }
}
