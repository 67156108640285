import { Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Store } from '@ngxs/store';
import { Observable, filter, take } from 'rxjs';

export function selectIfDefined<T>(
  store: Store,
  selector: (state: any, ...states: any[]) => T | undefined | null
): Observable<T> {
  return store.select(selector).pipe(filter((value): value is T => value !== undefined && value !== null));
}

export function selectOnceIfDefined<T>(
  store: Store,
  selector: (state: any, ...states: any[]) => T | undefined | null
): Observable<T> {
  return selectIfDefined(store, selector).pipe(take(1));
}

export function selectIfDefinedSig<T>(
  store: Store,
  selector: (state: any, ...states: any[]) => T | undefined | null
): Signal<T | undefined> {
  return toSignal<T>(selectIfDefined(store, selector));
}
