import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { FEATURE_MENU } from '@commons/menu/feature-menu';
import { getBreadcrumbsFromUrl } from '@commons/utils/breadcrumb';
import { Breadcrumb } from '@models/breadcrumb';
import { Action, NgxsOnInit, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { insertItem, patch } from '@ngxs/store/operators';
import { AddBreadcrumb, RemoveCustomBreadcrumbs } from '@stores/breadcrumb/breadcrumb.actions';
import { filter, map } from 'rxjs';

export type BreadcrumbsStateModel = {
  routerBreadcrumbs: Breadcrumb[];
  customBreadcrumb: Breadcrumb[];
};
const BREADCRUMBS_STATE_TOKEN = new StateToken<BreadcrumbsStateModel>('breadcrumbs');

@State<BreadcrumbsStateModel>({
  name: BREADCRUMBS_STATE_TOKEN,
  defaults: {
    routerBreadcrumbs: [],
    customBreadcrumb: [],
  },
})
@Injectable()
export class BreadcrumbsState implements NgxsOnInit {
  @Selector([BreadcrumbsState])
  static breadcrumbs(state: BreadcrumbsStateModel): Breadcrumb[] {
    return [...state.routerBreadcrumbs, ...state.customBreadcrumb];
  }

  constructor(private readonly router: Router) {}

  ngxsOnInit(context?: StateContext<BreadcrumbsStateModel>): void {
    this.router.events
      .pipe(
        filter((routerEvent) => routerEvent instanceof NavigationEnd),
        map(() => this.router.url)
      )
      .subscribe({
        next: (url) =>
          context?.setState(
            patch<BreadcrumbsStateModel>({
              routerBreadcrumbs: getBreadcrumbsFromUrl(url, FEATURE_MENU),
            })
          ),
      });
  }

  @Action(AddBreadcrumb)
  addBreadcrumb(context: StateContext<BreadcrumbsStateModel>, { label }: AddBreadcrumb): void {
    context?.setState(patch<BreadcrumbsStateModel>({ customBreadcrumb: insertItem<Breadcrumb>({ label, route: [] }) }));
  }

  @Action(RemoveCustomBreadcrumbs)
  removeBreadcrumb(context: StateContext<BreadcrumbsStateModel>): void {
    context?.setState(patch<BreadcrumbsStateModel>({ customBreadcrumb: [] }));
  }
}
