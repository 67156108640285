export enum ClientEnum {
  WizbiiEvents = 'wizbii-events',
  Youzful = 'youzful',
  SocieteGenerale = 'societe-generale',
  UnJeuneUneSolution = '1jeune1solution',
}

export const ClientLogos: Record<ClientEnum, string | undefined> = {
  [ClientEnum.WizbiiEvents]: undefined,
  [ClientEnum.Youzful]: 'logo-youzful',
  [ClientEnum.SocieteGenerale]: 'logo-soge',
  [ClientEnum.UnJeuneUneSolution]: 'logo-1j1s',
};
