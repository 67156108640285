import { EditorConfig } from '@ckeditor/ckeditor5-core';

export const ckEditorConfig: EditorConfig = {
  toolbar: {
    items: [
      'heading',
      '|',
      'fontSize',
      'fontFamily',
      'fontBackgroundColor',
      'fontColor',
      '|',
      'bold',
      'italic',
      'underline',
      'strikethrough',
      'highlight',
      '|',
      'alignment',
      '|',
      'numberedList',
      'bulletedList',
      '|',
      'indent',
      'outdent',
      '|',
      'todoList',
      'link',
      'blockQuote',
    ],
  },
  placeholder: 'Description',
  language: 'fr',
  image: {
    toolbar: ['imageTextAlternative', 'imageStyle:full', 'linkImage'],
  },
  table: {
    contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
  },
  licenseKey: '',
  link: { addTargetToExternalLinks: true, defaultProtocol: 'https://' },
};
