import { ChangeDetectionStrategy, Component, effect, inject, signal } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { InfoModalComponent } from '@commons/components/modals/info-modal/info-modal.component';
import { FEATURE_MENU } from '@commons/menu/feature-menu';
import { BreakpointsService } from '@core/services/breakpoints/breakpoints.service';
import { selectIfDefinedSig } from '@core/utils/selects.operators';
import { JobOfferCardComponent } from '@domains/job-offer/job-offer-card/job-offer-card.component';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { JobsRoutingEnum } from '@features/jobs/jobs-routing.enum';
import { Store } from '@ngxs/store';
import { CompanyParticipationState } from '@stores/company-participation/company-participation.state';
import { JobOfferWebservice } from '@webservices/cv-book-api/job-offer/job-offer.webservice';
import { OpsJobStatusEnum } from '@wizbii/utils/models';
import { derivedAsync } from 'ngxtension/derived-async';
import { Observable, tap } from 'rxjs';

@Component({
  standalone: true,
  imports: [JobOfferCardComponent, MatIcon, MatTooltip],
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobsComponent {
  readonly FeaturesRoutingEnum = FeaturesRoutingEnum;
  readonly JobsRoutingEnum = JobsRoutingEnum;
  readonly maxNumJobOffers = 5;

  get isMobile$(): Observable<boolean> {
    return this.#breakpoints.isMobile$;
  }

  get isTablet$(): Observable<boolean> {
    return this.#breakpoints.isTablet$;
  }

  get matTooltipText(): string {
    return `${this.numJobOffers()} ${this.numJobOffers() > 1 ? ' fiches' : ' fiche'} de poste ${
      this.numJobOffers() > 1 ? ' créées' : ' créée'
    } sur un maximum de ${this.maxNumJobOffers} (hors fiche(s) refusée(s))`;
  }

  readonly #jobOfferWebservice = inject(JobOfferWebservice);
  readonly #breakpoints = inject(BreakpointsService);
  readonly #router = inject(Router);
  readonly modalDialog = inject(MatDialog);
  readonly #store = inject(Store);

  readonly isJobEditionAllowed = selectIfDefinedSig(this.#store, CompanyParticipationState.isJobEditionAllowed);
  readonly numJobOffers = signal(0);
  readonly jobOffers = derivedAsync(() => {
    return this.#jobOfferWebservice.getCompanyJobOffers().pipe(
      tap((jobOffers) => {
        const numJobOffers = jobOffers.filter(
          (jobOffer) => jobOffer.status !== OpsJobStatusEnum.STATUS_REJECTED
        ).length;
        this.numJobOffers.set(numJobOffers);
        return numJobOffers;
      })
    );
  });

  get pageTitle(): string {
    return FEATURE_MENU[FeaturesRoutingEnum.Jobs].label;
  }

  constructor() {
    effect(() => {
      const jobOffers = this.jobOffers();

      if (jobOffers?.length === 0) {
        this.#router?.navigate(['/', FeaturesRoutingEnum.Jobs, JobsRoutingEnum.Create]);
      }
    });
  }

  clickOnNew(isMaxOfferReached: boolean): void {
    isMaxOfferReached
      ? this.displayErrorMaxReached()
      : this.#router?.navigate(['/', FeaturesRoutingEnum.Jobs, JobsRoutingEnum.Create]);
  }

  displayErrorMaxReached(): void {
    this.modalDialog.open(InfoModalComponent, {
      data: {
        title: 'Le nombre maximal de fiches de poste est atteint',
        subtitle: 'Merci de vous rapprocher de nos équipes pour supprimer ou modifier une fiche',
        navBtn: {
          routerLink: FeaturesRoutingEnum.Jobs,
          wording: 'Revenir aux fiches de poste',
        },
      },
    });
  }
}
