<!-- eslint-disable @angular-eslint/template/no-call-expression -->
@if (jobStatus) {
  @if (jobOffer(); as jobOffer) {
    <div class="job-offer">
      <a class="job-offer__description" [routerLink]="['/', FeaturesRoutingEnum.Jobs, jobOffer.id]">
        <h3 class="job-offer__description__title">{{ jobOffer.name }}</h3>
        <p [class]="'chip-light--' + jobStatus.scssClass">
          <mat-icon
            [class]="'chip-light--' + jobStatus.scssClass + '__icon'"
            [svgIcon]="jobStatus.icon"
            aria-hidden="true"
            focusable="false"
          />{{ jobStatus.label }}
        </p>
      </a>
      <div class="job-offer__btns">
        @if (jobStatus.edit && isJobEditionAllowed()) {
          <a class="href-clean btn-emphasis-ghost job-offer__btns__btn" [routerLink]="[jobOffer.id]">
            <mat-icon
              class="btn-emphasis-ghost__icon--left"
              svgIcon="pen"
              aria-hidden="true"
              focusable="false"
            />Modifier</a
          >
        } @else {
          <a
            class="href-clean btn-emphasis-ghost job-offer__btns__btn"
            [routerLink]="['/', FeaturesRoutingEnum.Jobs, jobOffer.id]"
            >Aperçu</a
          >
        }
      </div>
    </div>
  }
}
