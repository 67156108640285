import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { AuthService } from '@webservices/auth/auth.service';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CvBookInterceptor implements HttpInterceptor {
  readonly #authService = inject(AuthService);

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.#authService.getTokenFromStore().pipe(
      filter((token): token is string => !!token),
      take(1),
      switchMap((token: string) => {
        if (req.url.includes('https://file.')) {
          return next.handle(req);
        }
        const apiReq = req.clone({
          params: req.params.set('token', token || this.#authService.getTokenFromCookies()),
        });
        return next.handle(apiReq);
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error);
      })
    );
  }
}
