import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ChangeDetectionStrategy, Component, ViewChild, forwardRef, input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { MatChipGrid, MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';

@Component({
  standalone: true,
  selector: 'app-software-skills-picker',
  imports: [MatFormFieldModule, MatInputModule, MatSelectModule, MatChipsModule, ReactiveFormsModule, MatIcon],
  templateUrl: './software-skills-picker.component.html',
  styleUrls: ['./software-skills-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SoftwareSkillsPickerComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SoftwareSkillsPickerComponent implements ControlValueAccessor {
  selectedSoftwareSkills: string[] = [];

  readonly disabled = input<boolean>(false);

  // to set error state on the mat-chip-list element
  @ViewChild('softwareChips') softwareChips!: MatChipGrid;

  readonly separatorKeysCodes = [ENTER, COMMA];

  onChange: (value: any) => void = () => {};

  writeValue(val: string[]): void {
    this.selectedSoftwareSkills = val;
  }

  registerOnChange(fn: (software: string[]) => void): void {
    this.onChange = (software) => fn(software);
  }

  registerOnTouched(): void {}

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    if (value) {
      this.selectedSoftwareSkills.push(value);
    }

    event.chipInput?.clear();

    this.onChange(this.selectedSoftwareSkills);
  }

  remove(softwareSkill: string): void {
    const index = this.selectedSoftwareSkills.indexOf(softwareSkill);

    if (index >= 0) {
      this.selectedSoftwareSkills.splice(index, 1);
    }

    this.onChange(this.selectedSoftwareSkills);
  }
}
