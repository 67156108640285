import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  OnInit,
  ViewChild,
  computed,
  forwardRef,
  inject,
  input,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipGrid, MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { QualitiesEnum } from '@wizbii/models';
import { derivedAsync } from 'ngxtension/derived-async';
import { map, startWith } from 'rxjs';

@Component({
  standalone: true,
  selector: 'app-qualities-picker',
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatChipsModule,
    ReactiveFormsModule,
    MatIcon,
  ],
  templateUrl: './qualities-picker.component.html',
  styleUrls: ['./qualities-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => QualitiesPickerComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => QualitiesPickerComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QualitiesPickerComponent implements OnInit, ControlValueAccessor, Validator {
  control = new FormControl();
  selectedQualities: string[] = [];

  readonly separatorKeysCodes = [ENTER, COMMA];

  readonly searchResults = derivedAsync(() => {
    return this.control.valueChanges.pipe(
      startWith(null),
      takeUntilDestroyed(this.#destroyRef),
      map((request: string) => (request ? this._filter(request) : Object.values(QualitiesEnum)))
    );
  });
  readonly resultsFound = computed(() => {
    const searchResults = this.searchResults();
    return searchResults && searchResults.length > 0;
  });

  // to reset the input field after a quality is selected
  @ViewChild('qualityInputField') qualityInputField!: ElementRef<HTMLInputElement>;

  // to set error state on the mat-chip-list element
  @ViewChild('qualityChips') qualityChips!: MatChipGrid;

  readonly disabled = input<boolean>(false);

  readonly #destroyRef = inject(DestroyRef);
  readonly #cdr = inject(ChangeDetectorRef);

  ngOnInit(): void {
    this.control.statusChanges.pipe(takeUntilDestroyed(this.#destroyRef)).subscribe({
      next: (status) => {
        this.qualityChips.errorState = status === 'INVALID';
      },
    });
  }

  onChange: (value: any) => void = () => {};
  registerOnTouched(): void {}

  writeValue(val: string[]): void {
    this.selectedQualities = val;
    this.#cdr.markForCheck();
  }

  registerOnChange(fn: (qualities: string[]) => void): void {
    this.onChange = (qualities) => fn(qualities);
  }

  validate(): ValidationErrors | null {
    return null;
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    if (value) {
      this.selectedQualities.push(value);
    }

    event.chipInput?.clear();
  }
  selected(event: MatAutocompleteSelectedEvent): void {
    this.selectedQualities.push(event.option.value);
    this.qualityInputField.nativeElement.value = '';
    this.onChange(this.selectedQualities);
  }

  remove(quality: string): void {
    const index = this.selectedQualities.indexOf(quality);
    if (index >= 0) this.selectedQualities.splice(index, 1);
    this.onChange(this.selectedQualities);
    this.control.updateValueAndValidity();
  }

  private _filter(qualityInput: string): any[] {
    return Object.values(QualitiesEnum).filter(
      (quality) =>
        !this.selectedQualities.includes(quality) && quality.toLowerCase().includes(qualityInput.toLowerCase())
    );
  }
}
