<!-- eslint-disable @angular-eslint/template/no-call-expression -->
<mat-form-field class="custom-chips" appearance="outline">
  <mat-label>Qualités</mat-label>

  <mat-chip-grid [disabled]="disabled()" #qualityChips aria-label="Sélection des qualités">
    @for (quality of selectedQualities; track quality) {
      <mat-chip-row (removed)="remove(quality)">
        {{ quality }}
        @if (!disabled()) {
          <button class="button-remove" matChipRemove type="button">
            <mat-icon class="button-remove__icon" svgIcon="cross" />
          </button>
        }
      </mat-chip-row>
    }
    <input
      [placeholder]="disabled() || selectedQualities.length ? '' : 'Ajouter une qualité et appuyer sur Entrée'"
      [formControl]="control"
      [matAutocomplete]="qualityAutocomplete"
      [matChipInputFor]="qualityChips"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="add($event)"
      #qualityInputField
    />
  </mat-chip-grid>

  <mat-autocomplete (optionSelected)="selected($event)" #qualityAutocomplete="matAutocomplete">
    @for (quality of searchResults(); track quality) {
      <mat-option class="mat-option" [value]="quality">{{ quality }}</mat-option>
    }

    @if (resultsFound() === false) {
      <mat-optgroup class="mat-optgroup--error" label="Aucune suggestion ne correspond à votre saisie.">
        <mat-option class="invisible-option" />
      </mat-optgroup>
    }
  </mat-autocomplete>
</mat-form-field>
