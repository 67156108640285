<!-- eslint-disable @angular-eslint/template/no-call-expression -->
<nav class="breadcrumbs" role="navigation">
  <ul class="ul-clean breadcrumbs__list">
    <li class="breadcrumbs__list__item">
      <a class="breadcrumbs__list__item__home" [routerLink]="['/']" aria-label="Aller à la page d'accueil">
        <mat-icon
          class="breadcrumbs__list__item__home__icon"
          svgIcon="home"
          title="Page d’accueil"
          aria-hidden="true"
          focusable="false"
        />
      </a>
    </li>
    @for (breadcrumb of breadcrumbs(); track breadcrumb) {
      <li class="breadcrumbs__list__item">
        <mat-icon
          class="breadcrumbs__list__item__separator-icon"
          svgIcon="angle-right"
          aria-hidden="true"
          focusable="false"
        />
        @if (breadcrumb.route.length) {
          <a
            class="breadcrumbs__list__item__link"
            [routerLink]="['/', breadcrumb.route[0]]"
            routerLinkActive="breadcrumbs__list__item__link--active"
            >{{ breadcrumb.label }}</a
          >
        }
        @if (!breadcrumb.route.length) {
          <p class="breadcrumbs__list__item__link">{{ breadcrumb.label }}</p>
        }
      </li>
    }
  </ul>
</nav>
