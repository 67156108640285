<!-- eslint-disable @angular-eslint/template/no-call-expression -->
<nav class="side-nav" role="navigation">
  <div class="side-nav__head">
    <div class="side-nav__head__logo">
      <app-company-logo [companyLogoUrl]="companyLogo()" [companyName]="companyName()" />
    </div>

    <p class="side-nav__head__name">
      {{ companyName() }}
    </p>
  </div>
  @if (featuresMenuKeys && featuresMenuKeys.length > 0) {
    <ul class="side-nav__list ul-clean">
      @for (feature of featuresMenuKeys; track feature) {
        <li class="side-nav__list__item" routerLinkActive="side-nav__list__item--active">
          @if (FEATURE_MENU[feature]; as currFeature) {
            @if (FEATURE_MENU[feature].path; as route) {
              <a
                class="side-nav__list__item__link"
                [routerLink]="route"
                routerLinkActive="side-nav__list__item__link--active"
              >
                <mat-icon
                  class="side-nav__list__item__link__icon"
                  [svgIcon]="currFeature.icon"
                  role="presentation"
                  aria-hidden="true"
                  focusable="false"
                />
                <p class="side-nav__list__item__link__label">
                  {{ (isMobile$ | async) || (isTablet$ | async) ? currFeature.shortLabel : currFeature.label }}
                </p>
              </a>
            }
          }
        </li>
      }
    </ul>
  }
</nav>
